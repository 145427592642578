import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { Button, CircularProgress, Typography } from "@mui/material";

export default function ExamserversBreakdown() {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.get(
      `servermanager/examServerList/${id}`
    );

    if (data) {
      setReport(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      Exam servers breakdown
      <div className="mt-4 mb-4">
        {loading && <CircularProgress />}
        {report && (
          <div>
            <div className="mb-3 col-lg-6 ">
              <Typography variant="h5" gutterBottom textTransform={"uppercase"}>
                {report.examination.title}
              </Typography>
              <Typography color="GrayText">exam servers and centres</Typography>
            </div>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Server ID</th>
                  <th>State</th>
                  <th>Centre Name</th>
                  <th>Candidates</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {report.centres.map((c, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Typography>{c.centreId}</Typography>
                    </td>
                    <td>
                      <Typography>{c.state}</Typography>
                    </td>
                    <td className="col-lg-6">
                      <Typography>{c.centreName}</Typography>
                    </td>
                    <CandidatesNumber id={c._id} />
                    <td>
                      <Button
                        onClick={() =>
                          navigate(`/serversmanager/centrescandidates/${c._id}`)
                        }
                      >
                        view
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>S/N</th>
                  <th>Server ID</th>
                  <th>State</th>
                  <th>Centre Name</th>
                  <th>Candidates</th>
                  <th>View</th>
                </tr>
              </tfoot>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
}

function CandidatesNumber({ id }) {
  const [number, setNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.get(
      `servermanager/candidatesnumber/${id}`
    );
    if (data) setNumber(data.number);

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <td>
      {" "}
      {loading && <CircularProgress size={13} />}
      {number}
    </td>
  );
}
