import React, { useState, useContext } from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { STATESLIST } from "../../utils/states";
import Swal from "sweetalert2";
import { httpService } from "../../httpService";
import { AlertContext } from "../../contexts/AlertContext";

export default function CreateServer() {
  const [state, setState] = useState("");
  const { setAlertData } = useContext(AlertContext);
  const [newServer, setNewServer] = useState(null);
  const [loading, setLoading] = useState(false);

  const createServer = (e) => {
    e.preventDefault();

    Swal.fire({
      icon: "question",
      title: "Create Server",
      text: "Do you want to create the server under this state?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.post(
          "servermanager/provision",
          { state }
        );
        if (data) {
          setNewServer(data);
        }
        if (error) {
          setAlertData({ message: error, open: true, severity: "error" });
        }
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" fontWeight={700}>
          Create Server
        </Typography>
      </div>
      <div className="mb-3 col-lg-4">
        <form onSubmit={createServer}>
          <div className="mb-3">
            <TextField
              onChange={(e) => setState(e.target.value)}
              select
              fullWidth
              label="State"
            >
              {STATESLIST.sort().map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <LoadingButton loading={loading} variant="contained" type="submit">
            Provision Server
          </LoadingButton>
        </form>
      </div>
      {newServer && (
        <div className="col-lg-4 alert alert-light">
          <div className="mb-2">
            <Typography gutterBottom variant="caption">
              Centre ID:
            </Typography>
            <Typography fontWeight={700}>{newServer.centreId}</Typography>
          </div>
          <div className="mb-2">
            <Typography gutterBottom variant="caption">
              Password:
            </Typography>
            <Typography fontWeight={700}>{newServer.password}</Typography>
          </div>
          <div className="mb-2">
            <Typography gutterBottom variant="caption">
              State:
            </Typography>
            <Typography fontWeight={700}>{newServer.state}</Typography>
          </div>
        </div>
      )}
    </div>
  );
}
