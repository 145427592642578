import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import AllExaminations from "./AllExaminations";
import PaperType from "./PaperType";
import PaperTypeCandidates from "./PaperTypeCandidates";
import ExamSchedule from "./ExamSchedule";
import MonitoringDashboard from "./MonitoringDashboard";
import PaperTypeResults from "./PaperTypeResults";
import CentreResult from "./CentreResult";
import Transcript from "./Transcript";

const privateRoutes = [
  { path: "/", component: AllExaminations },
  { path: "/papertype/:id", component: PaperType },
  { path: "/papertype/results/:id", component: PaperTypeResults },
  { path: "/papertype/candidates/:id", component: PaperTypeCandidates },
  { path: "/papertype/examschedule/:id", component: ExamSchedule },
  { path: "/papertype/monitoring/:id", component: MonitoringDashboard },
  { path: "/papertype/centreresult", component: CentreResult },
  { path: "/transcript", component: Transcript },
  { path: "*", component: NotFound },
];
const publicRoutes = [{ path: "*", component: NotFound }];

export default function ExaminationRoutes() {
  return (
    <div>
      <Routes>
        {privateRoutes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </div>
  );
}
