import React, { useEffect, useState, useRef } from "react";
import { httpService } from "../../httpService";
import { useParams } from "react-router-dom";
import { CircularProgress, Typography, Button } from "@mui/material";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { JsonToExcel } from "react-json-to-excel";

const formatReport = (data) => {
  const finalReport = [];

  for (let i = 0; i < data.length; i++) {
    finalReport.push({
      Name: `${data[i].candidate.firstName} ${data[i].candidate.lastName}`,
      "Exam Number": `${data[i].candidate.registrationNumber}`,
      School: data[i].candidate.school,
      "Number of Questions": data[i].questionLength,
      Responses: data[i].totalAttempts,
      "Correct Responses": data[i].totalCorrect,
      Percentage: data[i].percentage,
    });
  }
  return finalReport;
};

export default function PaperTypeResults() {
  const tableRef = useRef(null);
  const [resultData, setResultData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formatted, setFormatted] = useState([]);
  const { id } = useParams();
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`results/papertype/${id}`);

    if (data) {
      setFormatted(formatReport(data.results));
      setResultData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  function changeTextColor(totalQuestions, totalResponses) {
    const result = Math.floor((totalResponses / totalQuestions) * 100);

    if (result >= 80) return "green"; //green color for good results
    else if (result >= 60 && result < 80) return "orange";
    return "red"; //red color for bad results
  }
  return (
    <div>
      {loading && <CircularProgress />}

      {resultData && resultData.paperType && (
        <div>
          <div className="alert alert-info col-lg-4 mb-4">
            <Typography variant="h6" textTransform={"uppercase"}>
              {resultData.paperType.examination.title}
            </Typography>
            <Typography textTransform={"capitalize"}>
              {resultData.paperType.title}-{" "}
              <em>{resultData.paperType.formName}</em>
            </Typography>
          </div>
          <div>
            <JsonToExcel
              title="Download Result"
              data={formatted}
              fileName={"Exam Results"}
              btnClassName="btn btn-success"
            />

            {/* {tableRef.current && (
              <DownloadTableExcel
                filename={resultData.paperType.title}
                sheet="result"
                currentTableRef={tableRef.current}
              >
                <Button variant="contained"> download results </Button>
              </DownloadTableExcel>
            )} */}
            <Table ref={tableRef} striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Examination Number</th>
                  <th>School</th>
                  <th>Total Questions</th>
                  <th>Total Attempts</th>
                  <th>Total Correct</th>
                  <th>Paper Score</th>
                </tr>
              </thead>
              <tbody>
                {resultData.results.length > 0 ? (
                  <>
                    {resultData.results.map((c, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Typography>
                            {c.candidate.firstName} {c.candidate.lastName}{" "}
                            {c.candidate.middleName}
                            {c.middleName}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.candidate.registrationNumber}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"capitalize"}>
                            {c.candidate.school}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"capitalize"}>
                            {c.questionLength}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            textTransform={"capitalize"}
                            color={changeTextColor(
                              c.questionLength,
                              c.totalAttempts
                            )}
                          >
                            {c.totalAttempts}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"capitalize"}>
                            {c.totalCorrect}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"capitalize"}>
                            {c.percentage}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center">
                      NO RESULTS YET
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Examination Number</th>
                  <th>School</th>
                  <th>Total Questions</th>
                  <th>Total Attempts</th>
                  <th>Total Correct</th>
                  <th>Paper Score</th>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
