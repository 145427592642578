import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AlertContext } from "../../contexts/AlertContext";

export default function OsceComponent() {
  const { id } = useParams();
  const [programme, setProgramme] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const [updating, setUpdating] = useState(false);

  const [osceData, setOsceData] = useState({});
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`programme/view/${id}`);

    if (data) {
      setProgramme(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    setOsceData({ ...osceData, [e.target.name]: e.target.value });
  };

  const totalScore = () => {
    const values = Object.values(osceData);

    let totalScore = 0;

    values.forEach((c) => (totalScore += Number(c)));

    return totalScore;
  };

  const updateComponent = async () => {
    setUpdating(true);
    const { data, error } = await httpService.patch(
      `programme/updatecomponent/${id}`,
      osceData
    );
    if (data) {
      getData();
      setAlertData({ open: true, message: data, severity: "success" });
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setUpdating(false);
  };
  return (
    <div>
      {loading && <CircularProgress />}
      {programme && (
        <div>
          <div className="mb-4">
            <Typography variant="h6" textTransform={"uppercase"}>
              PROGRAMS / {programme.name}
            </Typography>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="mb-2">
                <Typography>OSCE</Typography>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Score"
                    name="osce"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <Typography>RESEARCH</Typography>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Score"
                    name="research"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <Typography>CLIENT CARE</Typography>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Score"
                    name="clientCare"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <Typography>EXPECTANT FAMILY CARE</Typography>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Score"
                    name="expectantFamilyCare"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <Typography>VIVA</Typography>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    type="number"
                    label="Max Score"
                    name="viva"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <Typography variant="h6">
                  Total Score: {totalScore()}
                </Typography>
              </div>
              <div>
                <LoadingButton
                  disabled={totalScore() !== 100}
                  variant="contained"
                  onClick={updateComponent}
                  loading={updating}
                >
                  UPDATE OSCE COMPONENT
                </LoadingButton>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="alert alert-light mb-2">
                OSCE: {programme.osce}
              </div>
              <div className="alert alert-light mb-2">
                RESEARCH: {programme.research || "-"}
              </div>
              <div className="alert alert-light mb-2">
                CLIENT CARE: {programme.clientCare || "-"}
              </div>

              <div className="alert alert-light mb-2">
                EXPECTANT FAMILY CARE: {programme.expectantFamilyCare || "-"}
              </div>
              <div className="alert alert-light mb-2">
                VIVA: {programme.viva}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
