import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { httpService } from "../../httpService";
import { Table, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { AlertContext } from "../../contexts/AlertContext";
import { STATESLIST } from "../../utils/states";
import { LoadingButton } from "@mui/lab";

export default function ServersList() {
  const navigate = useNavigate();
  const [servers, setServers] = useState([]);
  const [activeExam, setActiveExam] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService("servermanager/all");

    if (data) setServers(data);
    setLoading(false);
  };

  const getActiveExam = async () => {
    const { data } = await httpService("examination/active");
    if (data) {
      setActiveExam(data);
    }
  };
  useEffect(() => {
    getData();
    getActiveExam();
  }, []);
  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" fontWeight={700}>
          SERVER MANAGER
        </Typography>
      </div>
      {loading && <CircularProgress />}
      {activeExam && (
        <div className="p-3 col-lg-6 border rounded-3">
          <Typography variant="caption">Active Examination:</Typography>
          <Typography variant="h4" textTransform={"uppercase"}>
            {activeExam.title}
          </Typography>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() =>
                navigate(`/serversmanager/examservers/${activeExam._id}`)
              }
            >
              servers for this examination
            </Button>
          </div>
        </div>
      )}

      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div>
            <Typography color="primary">List of servers</Typography>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => navigate("/serversmanager/createserver")}
            >
              create server
            </Button>
          </div>
        </div>
        <div className="col-lg-4">
          <Button
            onClick={() => navigate("/serversmanager/list")}
            variant="outlined"
            color="inherit"
          >
            servers list
          </Button>
        </div>
        <div className="row">
          <div>
            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Server ID</th>
                  <th>Server Password</th>
                  <th>State</th>
                  <th>Centre Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {servers.map((c, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Typography>{c.centreId}</Typography>
                    </td>
                    <td>
                      <Typography>{c.password}</Typography>
                    </td>
                    <td>
                      <Typography>{c.state}</Typography>
                    </td>
                    <td className="col-lg-4">
                      <Typography>{c.centreName}</Typography>
                    </td>
                    <td>
                      <DeleteServer server={c} getServers={getData} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

function DeleteServer(props) {
  const [deleting, setDeleting] = useState(false);
  const [serverDetail, setServerDetail] = useState(props.server);
  const [editing, setEditing] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const [show, setShow] = useState(false);
  const [resetting, setResetting] = useState(false);

  const deleteServer = () => {
    Swal.fire({
      icon: "question",
      title: ` Delete server ${props.server.centreId}?`,
      text: "Are you sure you want to delete this server?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleting(true);
        const { data, error } = await httpService.delete(
          `servermanager/delete/${props.server._id}`
        );

        if (data) {
          props.getServers();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }

        setDeleting(false);
      }
    });
  };

  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    setServerDetail({ ...serverDetail, [e.target.name]: e.target.value });
  };

  const saveChanges = () => {
    Swal.fire({
      icon: "question",
      title: "Save Changes",
      text: "Do you wish to save the changes you have made to this server?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setEditing(true);
        const { data, error } = await httpService.patch(
          "servermanager/editexamservers",
          serverDetail
        );
        if (data) {
          props.getServers();
          setAlertData({ open: true, message: data, severity: "success" });
          handleClose();
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
          handleClose();
        }
        setEditing(false);
      }
    });
  };

  const resetCentreDetails = () => {
    Swal.fire({
      icon: "question",
      title: "Reset Centre details",
      text: "Are you sure you want to reset this centre details?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setResetting(true);
        const { data, error } = await httpService.get(
          `centres/resetcentredetails/${props.server._id}`
        );
        if (data) {
          setAlertData({ open: true, message: data, severity: "success" });
          props.getServers();
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "success" });

        setResetting(false);
      }
    });
  };
  return (
    <>
      <Stack direction={"row"}>
        <Button onClick={handleShow}>edit</Button>
        <LoadingButton
          color="error"
          onClick={resetCentreDetails}
          loading={resetting}
        >
          reset centre details
        </LoadingButton>
        <IconButton onClick={deleteServer} color="error">
          {deleting ? (
            <CircularProgress size={20} color="error" />
          ) : (
            <FontAwesomeIcon icon={faTrash} />
          )}
        </IconButton>
      </Stack>
      <Modal onHide={handleClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Server {serverDetail.centreId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <TextField
              select
              fullWidth
              name="state"
              label="State"
              onChange={handleChange}
              value={serverDetail.state}
            >
              {STATESLIST.sort().map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="mb-3">
            <TextField
              multiline
              fullWidth
              label="Centre Name"
              name="centreName"
              onChange={handleChange}
              value={serverDetail.centreName}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            loading={editing}
            onClick={saveChanges}
            variant="contained"
            color="success"
          >
            Save changes
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
