import React, { useEffect, useState } from "react";
import { httpService } from "../../httpService";
import { Typography } from "@mui/material";

export default function SyncCandidate() {
  const [activeExam, setActiveExam] = useState(null);

  const getActiveExam = async () => {
    const { data } = await httpService("examination/activepopulated");
    if (data) {
      console.log(data);
      setActiveExam(data);
    }
  };

  useEffect(() => {
    getActiveExam();
  }, []);
  return (
    <div>
      Sync Candidate
      <div className="mt-3">
        {activeExam && (
          <div className="">
            <Typography variant="caption">Active Examination:</Typography>
            <Typography variant="h4" textTransform={"uppercase"}>
              {activeExam.title}
            </Typography>

            <div className="mt-3 col-lg-4"></div>
            {/* <div className="d-flex justify-content-end">
              <Button
                onClick={() =>
                  navigate(`/serversmanager/examservers/${activeExam._id}`)
                }
              >
                servers for this examination
              </Button>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
