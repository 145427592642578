import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";

export default function CreateAccountPage() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ role: "admin" });

  const { setAlertData } = useContext(AlertContext);

  const handleChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });
  const createAccount = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, error } = await httpService.post("auth/signup", userData);
    if (data) {
      localStorage.setItem(
        process.env.REACT_APP_PROJECT_USER,
        JSON.stringify(data)
      );
      window.location.assign("/");
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="container mt-5">
        <div className="mb-4">
          <h4>Create Admin Page</h4>
        </div>
        <div className="col-lg-4">
          <form onSubmit={createAccount}>
            <div className="mb-4">
              <TextField
                fullWidth
                label="First Name"
                required
                name="firstName"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <TextField
                fullWidth
                label="Last Name"
                required
                name="lastName"
                onChange={handleChange}
              />
            </div>{" "}
            <div className="mb-4">
              <TextField
                fullWidth
                label="Username"
                required
                name="username"
                onChange={handleChange}
              />
            </div>{" "}
            <div className="mb-4">
              <TextField
                fullWidth
                label="Password"
                type="password"
                required
                name="password"
                onChange={handleChange}
              />
            </div>
            <LoadingButton type="submit" loading={loading}>
              Create account
            </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  );
}
