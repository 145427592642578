import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Table } from "react-bootstrap";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function PaperType() {
  const { id } = useParams();
  const [examination, setExamination] = useState(null);
  const [paperTypes, setPaperTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const getExamination = async () => {
    setLoading(true);
    const { data, error } = await httpService(`examination/view/${id}`);

    if (data) setExamination(data);
    setLoading(false);
  };

  const viewPaperTypes = async () => {
    setLoading(true);
    const { data, error } = await httpService.post(
      "examination/viewpapertypes",
      {
        examination: id,
      }
    );

    if (data) setPaperTypes(data);
    setLoading(false);
  };

  useEffect(() => {
    getExamination();
    viewPaperTypes();
  }, []);

  const createNewPaperType = () => {
    Swal.fire({
      icon: "question",
      title: "Create new paper type",
      text: "Are you sure you want to create a new paper type?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data, error } = await httpService.post(
          "examination/createpapertype",
          { examination: id }
        );
        if (data) viewPaperTypes();
      }
    });
  };
  return (
    <div>
      {loading && <CircularProgress />}
      {examination && (
        <div className="mb-3">
          <div className="mb-4">
            <Typography
              variant="h4"
              textTransform={"uppercase"}
              fontWeight={600}
            >
              {examination.title}
            </Typography>
            <Typography variant="caption">Paper Type</Typography>
          </div>
          {/* <div className="mb-3">
            <LoadingButton onClick={createNewPaperType}>
              Create new paper type
            </LoadingButton>
          </div> */}
          <div className="col-lg-10">
            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Type</th>
                  <th>Form Name</th>
                  <th>View Candidates</th>
                  <th>Examination Schedule</th>
                  <th>Results</th>
                  {/* <th>Delete</th> */}
                </tr>
              </thead>
              <tbody>
                <>
                  {paperTypes.length > 0 ? (
                    <>
                      {paperTypes.map((c, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Typography textTransform={"capitalize"}>
                              {c.title}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              fontWeight={700}
                              textTransform={"uppercase"}
                              color="primary"
                            >
                              {c.formName}
                            </Typography>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/examination/papertype/candidates/${c._id}`
                                )
                              }
                            >
                              view
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/examination/papertype/examschedule/${c._id}`
                                )
                              }
                            >
                              view
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/examination/papertype/results/${c._id}`
                                )
                              }
                            >
                              view
                            </Button>
                          </td>
                          {/* <td>
                            <DeletePaperType
                              examination={id}
                              paperId={c._id}
                              getPaperTypes={viewPaperTypes}
                            />
                          </td> */}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <Typography textAlign={"center"}>
                          No paper type created yet
                        </Typography>
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

function DeletePaperType({ examination, paperId, getPaperTypes }) {
  const [deleting, setDeleting] = useState(false);

  const deletePaperType = () => {
    Swal.fire({
      icon: "question",
      title: "Delete paper type",
      text: "Are you sure you want to delete this paper type. There might be candidates attached to this paper type",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleting(true);
        const { data, error } = await httpService.post(
          "examination/deletepapertype",
          {
            examination,
            _id: paperId,
          }
        );

        if (data) getPaperTypes();
        setDeleting(false);
      }
    });
  };
  return (
    <>
      {deleting ? (
        <CircularProgress />
      ) : (
        <IconButton onClick={deletePaperType}>
          <Delete />
        </IconButton>
      )}
    </>
  );
}
