import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../NotFound";
import AllProgrammes from "./AllProgrammes";

import ViewQuestionBank from "./ViewQuestionBank";
import OsceComponent from "./OsceComponent";
import ViewExamQuestions from "./ViewExamQuestions";

const routes = [
  { path: "/", component: AllProgrammes },
  { path: "/questions/:id", component: ViewExamQuestions },
  { path: "/questionbank/:id", component: ViewQuestionBank },
  { path: "/osce/:id", component: OsceComponent },
  { path: "*", component: NotFound },
];

export default function ProgrammeRoutes() {
  return (
    <div>
      <Routes>
        {routes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </div>
  );
}
