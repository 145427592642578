import React, { useState, useEffect, useContext } from "react";
import { httpService } from "../../httpService";
import { useParams } from "react-router-dom";
import secondsTimeFormatter from "seconds-time-formatter";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Table, Modal, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AlertContext } from "../../contexts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faX, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function ExamSchedule() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [updatingTime, setUpdatingTime] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [duration, setDuration] = useState({ hr: "0", min: "0", sec: "0" });
  const [errorMin, setErrorMin] = useState(false);
  const [errorSec, setErrorSec] = useState(false);
  const [errorHr, setErrorHr] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [activating, setActivating] = useState(false);
  const [show, setShow] = useState(false);
  const [programme, setProgramme] = useState(null);
  const [questionBanks, setQuestionbanks] = useState([]);
  const [fetchingBanks, setFetchingBanks] = useState(false);
  const [addingToBank, setAddingToBank] = useState(false);
  const [concluding, setConcluding] = useState(false);
  const [session, setSession] = useState("");
  const { setAlertData } = useContext(AlertContext);

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`papertype/examschedule/${id}`);

    if (data) {
      const examTimer = secondsTimeFormatter.timeConvert({
        seconds: data.schedule.duration / 1000,
        format: "json",
      });

      setDuration({
        hr: examTimer.hours,
        min: examTimer.minutes,
        sec: examTimer.seconds,
      });
      setSchedule(data);
    }
    setLoading(false);
  };

  const setSessionDateTime = () => {
    Swal.fire({
      icon: "question",
      title: "Update session date or time",
      text: "Do you wish to update the date or time of this session",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUpdatingTime(true);
        const { data, error } = await httpService.patch(
          `examination/setsessiondatetime/${schedule.schedule._id}`,
          { scheduledDate, scheduledTime }
        );

        if (data) {
          setAlertData({ message: data, open: true, severity: "success" });
          getData();
        }
        if (error)
          setAlertData({ message: error, open: true, severity: "error" });

        setUpdatingTime(false);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleChangeDuration = (e) => {
    setDuration({ ...duration, [e.target.name]: e.target.value });
  };

  const updateDuration = async () => {
    setUpdating(true);
    const hours = Number(duration.hr) * 60 * 60;
    const minutes = Number(duration.min) * 60;
    const seconds = Number(duration.sec);
    const examTimer = (hours + minutes + seconds) * 1000;

    const { data } = await httpService.patch(
      `examination/duration/${schedule.schedule._id}`,
      { duration: examTimer }
    );

    if (data) {
      getData();
      setAlertData({ message: data, severity: "success", open: true });
    }
    setUpdating(false);
  };

  function MakeExamAvaialbleForDownload() {
    Swal.fire({
      icon: "question",
      title: `${
        schedule.schedule.available ? "Deactivate Exam" : "Activate Exam"
      }`,
      text: `${
        schedule.schedule.available
          ? "This will deactivate this exam and will make it unavaialble for download"
          : "This will activate this exam and make it available for download, worldwide"
      }`,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setActivating(true);
        const { data, error } = await httpService(
          `examination/makeexamavailable/${schedule.schedule._id}`
        );
        if (data) {
          getData();
          setAlertData({ message: data, severity: "success", open: true });
        }
        if (error) {
          setAlertData({ message: error, severity: "error", open: true });
        }
        setActivating(false);
      }
    });
  }

  const handleClose = () => {
    setShow(false);
    setProgramme(null);
    setQuestionbanks([]);
  };

  const addQuestionBank = (programme) => {
    setShow(true);

    getQuestionBanks(programme._id);
    setProgramme(programme);
  };

  const getQuestionBanks = async (id) => {
    setFetchingBanks(true);
    const { data } = await httpService(`programme/questionbanks/view/${id}`);
    if (data) {
      setQuestionbanks(data);
    }
    setFetchingBanks(false);
  };

  const addQuestionBankToProgramme = (programmeId, questionBankId) => {
    Swal.fire({
      icon: "question",
      title: "Add question bank",
      text: "Do you wish to add this question bank to this programme?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAddingToBank(true);
        const { data, error } = await httpService.patch(
          `examination/addquestionbanktoprogramme/${schedule.schedule._id}`,
          { programme: programmeId, questionBank: questionBankId }
        );

        if (data) {
          handleClose();
          getData();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }
        setAddingToBank(false);
      }
    });
  };

  const markPaperascomplete = () => {
    Swal.fire({
      icon: "question",
      title: "Mark this paper as concluded",
      text: "This will mark this paper as concluded. It will not be available for download again and modification",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setConcluding(true);
        const { data } = await httpService.get(
          `examination/paperconcluded/${schedule.schedule._id}`
        );

        if (data) {
          getData();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        setConcluding(false);
      }
    });
  };

  const updateSession = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "Update Session",
      text: "Are you sure you want to update the exam with the current session",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.patch(
          `examination/updatesession/${id}`,
          { session }
        );

        if (data) {
          getData();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="mb-5">
        {loading && <CircularProgress />}
        {schedule && (
          <>
            <div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-5">
                    <Typography
                      variant="h4"
                      textTransform={"uppercase"}
                      fontWeight={600}
                    >
                      {schedule.paperType.examination.title}
                    </Typography>
                    <Typography variant="body1" textTransform={"uppercase"}>
                      {schedule.paperType.title} exam schedule
                    </Typography>
                  </div>
                </div>
                <div className="col-lg-3 d-flex align-items-center">
                  <Button
                    onClick={() =>
                      navigate(`/examination/papertype/monitoring/${id}`)
                    }
                    startIcon={<FontAwesomeIcon icon={faDesktop} />}
                  >
                    monitoring dashboard
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="mb-3">
                    <Typography variant="h3" fontWeight={300} color="GrayText">
                      Question Banks
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Typography>
                      {
                        schedule.schedule.questionBanks.filter(
                          (c) => c.questionBank
                        ).length
                      }{" "}
                      of {schedule.schedule.questionBanks.length} added
                    </Typography>
                  </div>
                  <div>
                    <Table striped borderless>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Programme</th>
                          <th>Bank</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedule.schedule.questionBanks.map((c, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <Typography textTransform={"capitalize"}>
                                {c.programme.name}
                              </Typography>
                            </td>
                            <td>
                              {c.questionBank ? (
                                <Button
                                  disabled={schedule.schedule.paperConcluded}
                                  onClick={() => addQuestionBank(c.programme)}
                                >
                                  Bank Added
                                </Button>
                              ) : (
                                <Button
                                  color="error"
                                  disabled={schedule.schedule.paperConcluded}
                                  onClick={() => addQuestionBank(c.programme)}
                                >
                                  Add question bank
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="mb-3">
                    <Typography variant="h3" fontWeight={300} color="GrayText">
                      Scheduled Date and Time
                    </Typography>
                  </div>
                  <div>
                    <>
                      {" "}
                      <div>
                        <DatePicker
                          disabled={schedule.schedule.paperConcluded}
                          label="Select exam date"
                          sx={{ width: "80%" }}
                          onChange={(e) =>
                            setScheduledDate({
                              date: e.$d,
                              dateString: new Date(e).toDateString(),
                            })
                          }
                          disablePast
                          format="LL"
                        />
                      </div>
                      <div>
                        <TimePicker
                          disabled={schedule.schedule.paperConcluded}
                          label="Select exam time"
                          className="mt-4 mb-4"
                          sx={{ width: "80%" }}
                          onChange={(e) =>
                            setScheduledTime({
                              hour: e.hour(),
                              minute: e.minute(),
                            })
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <LoadingButton
                          onClick={setSessionDateTime}
                          loading={updatingTime}
                          color="warning"
                          disabled={schedule.schedule.paperConcluded}
                        >
                          set date and time for this paper
                        </LoadingButton>
                      </div>
                      <div className="mt-2">
                        {!schedule.schedule.scheduledDate && (
                          <Alert severity="warning">
                            <AlertTitle>NO DATE</AlertTitle>
                            No date for this session has been set yet
                          </Alert>
                        )}
                        {schedule.schedule.scheduledDate && (
                          <Alert severity="info">
                            <AlertTitle>Session Date</AlertTitle>
                            {schedule.schedule.scheduledDate.dateString}
                          </Alert>
                        )}
                      </div>
                      <div className="mt-2">
                        {!schedule.schedule.scheduledTime && (
                          <Alert severity="warning">
                            <AlertTitle>NO TIME</AlertTitle>
                            No time for this session has been set yet
                          </Alert>
                        )}
                        {schedule &&
                          schedule.schedule.scheduledDate &&
                          schedule.schedule.scheduledTime && (
                            <Alert severity="info">
                              <AlertTitle>Session Time</AlertTitle>
                              {new Date(
                                2023,
                                0,
                                1,
                                schedule.schedule.scheduledTime.hour,
                                schedule.schedule.scheduledTime.minute,
                                0
                              ).toLocaleTimeString()}
                            </Alert>
                          )}
                      </div>
                    </>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 ">
                  <div className="mb-3">
                    <Typography variant="h3" fontWeight={300} color="GrayText">
                      Paper Duration
                    </Typography>
                  </div>
                  <Stack direction="row" spacing={1} className="mb-1">
                    <div>
                      <TextField
                        placeholder="HH"
                        type="number"
                        disabled={schedule.schedule.paperConcluded}
                        value={duration.hr}
                        name="hr"
                        onChange={(e) => {
                          if (e.target.value > 3 || e.target.value < 0) {
                            setErrorHr(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorHr(false);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value > 3 || e.target.value < 0) {
                            setErrorHr(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorHr(false);
                          }
                          if (e.target.value === "") {
                            e.target.value = 0;

                            handleChangeDuration(e);
                          }
                        }}
                        error={errorHr}
                        helperText={
                          errorHr ? "Value must be between 0 & 3" : ""
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        placeholder="MM"
                        type="number"
                        disabled={schedule.schedule.paperConcluded}
                        value={duration.min}
                        name="min"
                        onChange={(e) => {
                          if (e.target.value > 59 || e.target.value < 0) {
                            setErrorMin(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorMin(false);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value > 59 || e.target.value < 0) {
                            setErrorMin(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorMin(false);
                          }
                          if (e.target.value === "") {
                            e.target.value = 0;

                            handleChangeDuration(e);
                          }
                        }}
                        error={errorMin}
                        helperText={
                          errorMin ? "Value must be between 0 & 59" : ""
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        disabled={schedule.schedule.paperConcluded}
                        placeholder="SS"
                        type="number"
                        value={duration.sec}
                        name="sec"
                        onChange={(e) => {
                          if (e.target.value > 59 || e.target.value < 0) {
                            setErrorSec(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorSec(false);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value > 59 || e.target.value < 0) {
                            setErrorSec(true);
                          } else {
                            handleChangeDuration(e);
                            setErrorSec(false);
                          }

                          if (e.target.value === "") {
                            e.target.value = 0;

                            handleChangeDuration(e);
                          }
                        }}
                        error={errorSec}
                        helperText={
                          errorSec ? "Value must be between 0 & 59" : ""
                        }
                      />
                    </div>
                  </Stack>
                  <div
                    className="mt-3 mb-3 p-3"
                    style={{ backgroundColor: "#1d2b67", color: "white" }}
                  >
                    <Typography>
                      {duration.hr} hours, {duration.min} minutes,{" "}
                      {duration.sec} seconds
                    </Typography>
                  </div>
                  <LoadingButton
                    onClick={updateDuration}
                    disabled={schedule.schedule.paperConcluded}
                    color="info"
                    loadingPosition="end"
                    loading={updating}
                    endIcon={<i class="fas fa-clock    "></i>}
                  >
                    set duration
                  </LoadingButton>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="mb-3">
                    <Typography variant="h3" fontWeight={300} color="GrayText">
                      Examination Deployment
                    </Typography>
                  </div>
                  <div>
                    <div className="mt-2 mb-2">
                      <div className="mb-3">
                        <Typography>
                          Set the current session to be downloaded by the
                          centres
                        </Typography>
                      </div>
                      <form onSubmit={updateSession}>
                        <div className="mb-2">
                          <TextField
                            required
                            label="session"
                            select
                            fullWidth
                            onChange={(e) => setSession(e.target.value)}
                          >
                            <MenuItem value="session 1">SESSION 1</MenuItem>
                            <MenuItem value="session 2">SESSION 2</MenuItem>
                            <MenuItem value="session 3">SESSION 3</MenuItem>
                          </TextField>
                        </div>
                        <div>
                          <LoadingButton
                            loading={updating}
                            variant="outlined"
                            type="submit"
                          >
                            update
                          </LoadingButton>
                        </div>
                      </form>
                    </div>
                    <div className="row">
                      <div className="p-3 border col-lg-6 mb-4 rounded-3 ">
                        <div className="mb-4">
                          <Typography
                            variant="h6"
                            fontWeight={700}
                            textTransform={"uppercase"}
                          >
                            Pre-deployment checklist
                          </Typography>
                        </div>
                        <div className="mb-3">
                          <Stack direction={"row"} spacing={2}>
                            <div>
                              <Typography>
                                All question banks populated
                              </Typography>
                            </div>
                            <div>
                              {schedule.schedule.questionBanks.filter(
                                (c) => c.questionBank
                              ).length ===
                              schedule.schedule.questionBanks.length ? (
                                <FontAwesomeIcon icon={faCheck} color="green" />
                              ) : (
                                <FontAwesomeIcon icon={faX} color="red" />
                              )}
                            </div>
                          </Stack>
                        </div>
                        <div className="mb-3">
                          <Stack direction={"row"} spacing={2}>
                            <div>
                              <Typography>Date and Time set</Typography>
                            </div>
                            <div>
                              {schedule.schedule.scheduledDate &&
                              schedule.schedule.scheduledTime ? (
                                <FontAwesomeIcon icon={faCheck} color="green" />
                              ) : (
                                <FontAwesomeIcon icon={faX} color="red" />
                              )}
                            </div>
                          </Stack>
                        </div>
                        <div className="mb-3">
                          <Stack direction={"row"} spacing={2}>
                            <div>
                              <Typography>Examination Duration</Typography>
                            </div>
                            <div>
                              {schedule.schedule.duration > 0 ? (
                                <FontAwesomeIcon icon={faCheck} color="green" />
                              ) : (
                                <FontAwesomeIcon icon={faX} color="red" />
                              )}
                            </div>
                          </Stack>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        {schedule.schedule.paperConcluded ? (
                          <Typography
                            variant="h6"
                            fontWeight={700}
                            color={"green"}
                          >
                            PAPER COMPLETED
                          </Typography>
                        ) : (
                          <LoadingButton
                            onClick={markPaperascomplete}
                            loading={concluding}
                          >
                            mark this paper as complete
                          </LoadingButton>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    {schedule.schedule.session ? (
                      <Badge>{schedule.schedule.session}</Badge>
                    ) : (
                      <Typography>No session set</Typography>
                    )}
                  </div>
                  {!schedule.schedule.available ? (
                    <LoadingButton
                      onClick={MakeExamAvaialbleForDownload}
                      color="success"
                      variant="contained"
                      loadingPosition="center"
                      loading={activating}
                      disabled={
                        !(
                          schedule.schedule.questionBanks.filter(
                            (c) => c.questionBank
                          ).length === schedule.schedule.questionBanks.length &&
                          schedule.schedule.scheduledDate &&
                          schedule.schedule.scheduledTime &&
                          schedule.schedule.duration > 0
                        ) || schedule.schedule.paperConcluded
                      }
                    >
                      MAKE EXAM AVAILABLE FOR DOWNLOAD
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      onClick={MakeExamAvaialbleForDownload}
                      color="error"
                      variant="contained"
                      loadingPosition="center"
                      loading={activating}
                      disabled={schedule.schedule.paperConcluded}
                    >
                      MAKE EXAM UNAVAILABLE FOR DOWNLOAD
                    </LoadingButton>
                  )}
                </div>
              </div>
            </div>
            {programme && (
              <Modal size="lg" show={show} centered onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ textTransform: "uppercase" }}
                  >
                    {programme.name}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb-4">
                    <Typography variant="h6">Question Banks</Typography>
                    <Typography>
                      Select a question bank for this programme
                    </Typography>
                  </div>
                  {fetchingBanks && <LinearProgress />}
                  <div>
                    <Table striped borderless>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Questions</th>
                          <th>Date Created</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {questionBanks.map((c, i) => (
                          <tr key={i}>
                            <td>
                              <Typography>{i + 1}</Typography>
                            </td>
                            <td>
                              <Typography>{c.questions}</Typography>
                            </td>
                            <td>
                              <Typography>
                                {new Date(c.dateCreated).toDateString()}
                                {", "}
                                {new Date(c.dateCreated).toLocaleTimeString()}
                              </Typography>
                            </td>
                            <td>
                              <LoadingButton
                                loading={addingToBank}
                                onClick={() =>
                                  addQuestionBankToProgramme(
                                    programme._id,
                                    c._id
                                  )
                                }
                              >
                                Select
                              </LoadingButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
}

// function QuestionBankButton({ programmeId, questionBankId,  }) {
//   const [loading, setLoading] = useState(false);
//   return <LoadingButton> Select</LoadingButton>;
// }
