import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { httpService } from "../../httpService";
import { AlertContext } from "../../contexts/AlertContext";
export default function SearchCandidate() {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const { setAlertData } = useContext(AlertContext);

  const [candidateDetail, setCandidateDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const getResult = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { data, error } = await httpService.post("candidates/result", {
      registrationNumber,
    });
    if (data) {
      console.log(data);
      setCandidateDetail(data);
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }

    setLoading(false);
  };
  return (
    <div>
      <div className="mb-4">
        <Typography
          textTransform={"uppercase"}
          variant="h4"
          fontWeight={600}
          gutterBottom
        >
          SEARCH CANDIDATE
        </Typography>
      </div>
      <div className="col-lg-4 mb-4">
        <form onSubmit={getResult}>
          <TextField
            label="Examination Number"
            fullWidth
            onChange={(e) => setRegistrationNumber(e.target.value)}
          />
          <div className="mt-2">
            <LoadingButton loading={loading} type="submit">
              Get result
            </LoadingButton>
          </div>
        </form>
      </div>
      {candidateDetail && (
        <div>
          <div>
            <div className="mb-3">
              <div className="mb-4">
                <img
                  alt="candidate"
                  src={`data:image/jpg;base64,${candidateDetail.candidate.avatar}`}
                  height={200}
                  width={200}
                />
              </div>
              <Typography variant="caption">Candidate:</Typography>
              <Typography variant="h6">
                {candidateDetail.candidate.firstName}{" "}
                {candidateDetail.candidate.lastName}
              </Typography>
            </div>
            <div className="mb-3">
              <Typography variant="caption">Examination Number:</Typography>
              <Typography textTransform={"uppercase"}>
                {candidateDetail.candidate.registrationNumber}{" "}
              </Typography>
            </div>
            {/* <div className="mb-3">
              <Typography variant="caption">Centre:</Typography>
              <Typography textTransform={"uppercase"}>
                {candidateDetail.candidate.centre?.centreId}{" "}
              </Typography>
            </div> */}
            <div className="mb-3">
              <Typography variant="caption">Programme:</Typography>
              <Typography textTransform={"capitalize"}>
                {candidateDetail.candidate.programme.name}
              </Typography>
            </div>
            <div className="mb-3">
              <Typography variant="caption">Results</Typography>
              <div className="row">
                {candidateDetail.results.map((c, i) => (
                  <div key={i} className="col-lg-3 border-end">
                    <div className="mb-3">
                      <Typography variant="h5" textTransform={"uppercase"}>
                        {c.paperType.title}
                      </Typography>
                    </div>
                    <div className="mb-2">
                      <Typography>
                        Number of questions: {c.questionLength || "-"}
                      </Typography>
                    </div>
                    <div className="mb-2">
                      <Typography>
                        Total Attempts: {c.totalAttempts || "-"}
                      </Typography>
                    </div>
                    <div className="mb-2">
                      <Typography>
                        Total Correct: {c.totalCorrect || "-"}
                      </Typography>
                    </div>

                    <div className="mb-2">
                      <Typography>
                        Total Score: {c.percentage || "-"}%
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
