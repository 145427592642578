import { Button } from "@mui/material";
import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function MyNavbar() {
  const navigate = useNavigate();
  const listItems = [
    {
      text: "Dashboard",

      redirectTo: "/",
    },
    // {
    //   text: "CBT Centres",
    //   icon: <House />,
    //   redirectTo: "/centres/centresmanager",
    // },

    {
      text: "Examination Control",

      redirectTo: `/examination`,
    },
    {
      text: "Sync Operation",

      redirectTo: `/candidates/sync`,
    },
    // {
    //   text: "Active Exam Session",
    //   icon: <FontAwesomeIcon icon={faClock} />,
    //   redirectTo: "/examination/activesession",
    // },
    // {
    //   text: "Session Report",
    //   icon: <Chat />,
    //   redirectTo: `/examination/sessionreport`,
    // },
    {
      text: "Programmes",

      redirectTo: `/programmes`,
    },
    {
      text: "Search Candidates",

      redirectTo: `/candidates/search`,
    },
    {
      text: "Servers Manager",

      redirectTo: `/serversmanager`,
    },

    // {
    //   text: "Admin Panel",
    //   icon: <AdminPanelSettings />,
    //   redirectTo: `/adminPanel`,
    // },
  ];
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">NMCN-Central</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {listItems.map((c, i) => (
                // <Nav.Link key={i} href={c.redirectTo}>
                //   {c.text}
                // </Nav.Link>
                <Nav.Item key={i}>
                  <Button onClick={() => navigate(c.redirectTo)}>
                    {c.text}
                  </Button>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
