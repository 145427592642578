import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { AlertContext } from "../../contexts/AlertContext";
import { LoadingButton } from "@mui/lab";

export default function ExamServers() {
  const { id } = useParams();
  const { setAlertData } = useContext(AlertContext);
  const [examination, setExamination] = useState(null);
  const [servers, setServers] = useState([]);
  const [selectedServers, setSelectedServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const getExam = async () => {
    setLoading(true);
    const { data, error } = await httpService.get(`examination/view/${id}`);
    if (data) {
      getServers();
      setExamination(data);
    }
    if (error) setLoading(false);
  };

  const getServers = async () => {
    const { data } = await httpService("servermanager/all");

    if (data) {
      getExamServers();
      setServers(data);
    }
  };

  const getExamServers = async () => {
    const { data } = await httpService(`servermanager/examservers/${id}`);

    if (data) {
      setSelectedServers(data.centres);
    }
    setLoading(false);
  };

  const selectServer = (c) => {
    const server = selectedServers.find((d) => d._id === c._id);
    if (!server) setSelectedServers([...selectedServers, c]);
  };

  const removeServer = (id) => {
    setSelectedServers(selectedServers.filter((c) => c._id !== id));
  };

  const addServersForExam = () => {
    Swal.fire({
      icon: "question",
      title: "Use these servers",
      text: "Do you want to use these servers for this examination",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAdding(true);
        const centres = selectedServers.map((c) => c._id);

        const { data, error } = await httpService.post(
          "servermanager/examservers",
          {
            examination: id,
            centres,
          }
        );

        if (data) {
          getExamServers();
          setAlertData({ message: data, open: true, severity: "success" });
        }
        if (error) {
          setAlertData({ message: error, open: true, severity: "error" });
        }
        setAdding(false);
      }
    });
  };
  useEffect(() => {
    getExam();
  }, []);

  const navigate = useNavigate();

  const selectAll = () => {
    setSelectedServers(servers.map((c) => c));
  };

  return (
    <div>
      {loading && <CircularProgress />}
      {examination && (
        <div>
          <div className="mb-3">
            <Typography>Server Selection</Typography>
          </div>
          <div className="mb-5 col-lg-8 alert alert-primary">
            <Typography variant="caption">active examination</Typography>
            <Typography
              variant="h4"
              fontWeight={700}
              textTransform={"uppercase"}
            >
              {examination.title}
            </Typography>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <Typography color="GrayText">Servers List</Typography>
              <div className="text-end">
                <Button onClick={selectAll} variant="contained" color="warning">
                  Select All
                </Button>
              </div>
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Server ID</th>
                    <th>Server Password</th>
                    <th>State</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {servers.map((c, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <Typography>{c.centreId}</Typography>
                      </td>
                      <td>
                        <Typography>{c.password}</Typography>
                      </td>
                      <td>
                        <Typography>{c.state}</Typography>
                      </td>
                      <td>
                        <Button
                          disabled={examination.candidatesAssignedToCentre}
                          onClick={() => selectServer(c)}
                        >
                          select
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="col-lg-6  border rounded p-3">
              <div className="mb-3">
                <Typography color="GrayText">Selected Servers</Typography>
              </div>
              {selectedServers.length > 0 ? (
                <>
                  <div>
                    <div className="row">
                      {selectedServers
                        .sort((a, b) =>
                          a.centreId > b.centreId
                            ? 1
                            : b.centreId > a.centreId
                            ? -1
                            : 0
                        )
                        .map((c, i) => (
                          <div
                            key={i}
                            className="p-2 col-lg-3 border p-2 me-2 mb-2"
                          >
                            <Stack direction={"row"} spacing={3}>
                              <div>
                                <Typography color="disabled">
                                  Server {c.centreId}
                                </Typography>
                                <Typography
                                  fontWeight={700}
                                  variant="caption"
                                  color="GrayText"
                                >
                                  {c.state}
                                </Typography>
                              </div>
                              <div>
                                <IconButton
                                  onClick={() => removeServer(c._id)}
                                  size="small"
                                  color="error"
                                  disabled={
                                    examination.candidatesAssignedToCentre
                                  }
                                >
                                  <FontAwesomeIcon icon={faX} />
                                </IconButton>
                              </div>
                            </Stack>
                          </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end">
                      {examination.candidatesAssignedToCentre ? (
                        <>
                          <div>
                            <div>
                              <Typography
                                variant="h6"
                                fontWeight={700}
                                color="green"
                              >
                                CANDIDATES HAVE BEEN ASSIGNED TO THESE CENTRES
                              </Typography>
                            </div>
                            <div>
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/serversmanager/examserversbreakdown/${id}`
                                  )
                                }
                              >
                                View exam centres breakdown
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <LoadingButton
                          loading={adding}
                          onClick={addServersForExam}
                        >
                          use these servers
                        </LoadingButton>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Typography color="error">
                    No server selected for this examination
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
