import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import SideMenu from "../components/SideMenu";
import NotFound from "../pages/NotFound";
import ProgrammeRoutes from "../pages/programmes/route";
import ExaminationRoutes from "../pages/examinations/route";
import CandidatesRoutes from "../pages/candidates/route";
import CentresRoutes from "../pages/centres/route";
import HomePage from "../pages/HomePage";
import { loggedInUser } from "../httpService";
import ServersManager from "../pages/serversmanager/route";
import ErrorBoundary from "../pages/ErrorBoundary";
import MyNavbar from "../components/MyNavbar";
import UsersPage from "../pages/UsersPage";
import CreateAccountPage from "../pages/CreateAccountPage";
import AttendanceRoutes from "../pages/attendanceSystem/route";

const privateRoutes = [
  { path: "/", component: Dashboard },

  //centres
  { path: "/centres/*", component: CentresRoutes },

  //subjects
  { path: "/programmes/*", component: ProgrammeRoutes },

  //examinations
  { path: "/examination/*", component: ExaminationRoutes },

  //candidates
  { path: "/candidates/*", component: CandidatesRoutes },

  //servers
  { path: "/serversmanager/*", component: ServersManager },

  //view the users
  { path: "/users", component: UsersPage },

  //for the attendance
  { path: "/attendance/*", component: AttendanceRoutes },

  { path: "*", component: NotFound },
];

const publicRoutes = [
  { path: "/", component: HomePage },
  { path: "/adminsignup", component: CreateAccountPage },
  { path: "*", component: NotFound },
];

function MainRoutes() {
  return (
    <BrowserRouter>
      <>
        {loggedInUser ? (
          <div>
            <div className="d-sm-block d-lg-none">
              <MyNavbar />
              <div className="mt-3 container mb-5">
                <ErrorBoundary>
                  <Routes>
                    {privateRoutes.map((c, i) => (
                      <Route key={i} path={c.path} element={<c.component />} />
                    ))}
                  </Routes>
                </ErrorBoundary>
              </div>
            </div>
            <div className="d-none d-lg-block">
              <div className="row overallPageHeight m-0">
                <div
                  className="col-lg-2 p-0"
                  style={{ backgroundColor: "#FAFAFA" }}
                >
                  <SideMenu />
                </div>
                <div className="col-lg-10">
                  <div className="mt-5 mb-5">
                    <ErrorBoundary>
                      <Routes>
                        {privateRoutes.map((c, i) => (
                          <Route
                            key={i}
                            path={c.path}
                            element={<c.component />}
                          />
                        ))}
                      </Routes>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Routes>
            {publicRoutes.map((c, i) => (
              <Route key={i} path={c.path} element={<c.component />} />
            ))}
          </Routes>
        )}
      </>
    </BrowserRouter>
  );
}
export default MainRoutes;
