import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Table } from "react-bootstrap";

export default function CentreResult() {
  const [responses, setResponses] = useState([]);
  const [centreDetail, setCentreDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useSearchParams();

  const navigate = useNavigate();
  const centre = query.get("centre");

  const paperType = query.get("papertype");

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(
      `results/centreresponse?centre=${centre}&paperType=${paperType}`
    );

    if (data) {
      setCentreDetail(data.centre);
      setResponses(data.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {loading && <CircularProgress />}
      {centreDetail && (
        <div>
          <div className="col-lg-4 alert alert-success">
            <Typography variant="h6">Centre {centreDetail.centreId}</Typography>
          </div>
          <div className="mt-3">
            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>CANDIDATE</th>
                  <th>REGISTRATION NUMBER</th>
                  <th>QUESTIONS</th>
                  <th>ATTEMPTS</th>
                  <th>TOTAL CORRECT</th>
                  <th>PERCENTAGE</th>
                  <th>TRANSCRIPT</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((c, i) => (
                  <tr
                    className={`${
                      c.totalAttempts > c.questionLength ? "table-danger" : ""
                    }`}
                  >
                    <td>{i + 1}</td>
                    <td>
                      {c.candidate.firstName} {c.candidate.lastName}
                    </td>
                    <td>
                      <Typography textTransform={"uppercase"}>
                        {c.candidate.registrationNumber}
                      </Typography>
                    </td>
                    <td>{c.questionLength}</td>
                    <td>{c.totalAttempts}</td>
                    <td>{c.totalCorrect}</td>
                    <td>{c.percentage}</td>
                    <td>
                      <Button
                        onClick={() =>
                          navigate(
                            `/examination/transcript?candidate=${c.candidate._id}&papertype=${paperType}`
                          )
                        }
                      >
                        view
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
