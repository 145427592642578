import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import QuestionBankCard from "./QuestionBankCard";
import { CircularProgress, Typography } from "@mui/material";

export default function ViewExamQuestions() {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [programme, setProgramme] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`programme/questions/${id}`);
    if (data) {
      setProgramme(data.programme);
      setQuestions(data.questions);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {loading && <CircularProgress />}
      {programme && (
        <>
          <div className="mb-2">
            <Typography
              gutterBottom
              variant="h5"
              fontWeight={700}
              textTransform={"uppercase"}
            >
              {programme.name}
            </Typography>
          </div>
          <div>
            {questions.map((c, i) => (
              <QuestionBankCard question={c} index={i} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
