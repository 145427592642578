import {
  Button,
  LinearProgress,
  TextField,
  Typography,
  Chip,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { httpService } from "../../httpService";
import { AlertContext } from "../../contexts/AlertContext";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
function ExaminationAttendance() {
  const [show, setShow] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState([]);
  const [examination, setExamination] = useState("");
  const { setAlertData } = useContext(AlertContext);
  const [activeExamination, setActiveExamination] = useState(false);
  const toggleShow = () => setShow(!show);

  const navigate = useNavigate();

  const createSchedule = (e) => {
    e.preventDefault();
    if (!activeExamination)
      return setAlertData({
        open: true,
        message: "No examination title",
        severity: "error",
      });
    else if (days.length === 0)
      return setAlertData({
        open: true,
        message: "Please select number of days for this examination",
        severity: "error",
      });
    else if (!days.every((c) => c.dateString))
      return setAlertData({
        open: true,
        message: "Please select a date for each examination day",
        severity: "error",
      });
    Swal.fire({
      icon: "question",
      title: "Create Schedule",
      text: "Are you sure you want to create this examination schedule?",
      showCancelButton: true,
      confirmButtonColor: "#34c73b",
      cancelButtonColor: "#f5365c",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.post(
          "attendance/createschedule",
          { examination: activeExamination._id, days }
        );

        if (data) {
          toggleShow();
          viewSchedules();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }
        setLoading(false);
      }
    });
  };

  const viewSchedules = async () => {
    setLoading(true);
    const { data } = await httpService("attendance/viewschedules");
    if (data) {
      //console.log(data);
      setSchedules(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    viewSchedules();
  }, []);

  const getActiveExamination = async () => {
    setLoading(true);
    const { data, error } = await httpService("attendance/activeexamination");
    if (data) {
      setActiveExamination(data);
      toggleShow();
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="mb-2">
        <Typography>Examination Attendance</Typography>
      </div>
      <div className="mb-3">
        <LoadingButton onClick={getActiveExamination} variant="contained">
          create examination
        </LoadingButton>
      </div>
      {loading && (
        <div className="col-lg-4">
          <LinearProgress />
        </div>
      )}
      <div>
        <Table striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Title</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Report</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {schedules.map((c, i) => (
              <tr>
                <td>
                  <Typography variant="body2" textTransform={"uppercase"}>
                    {i + 1}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" textTransform={"uppercase"}>
                    {c.examination.title}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" textTransform={"uppercase"}>
                    {c.days[0].dateString}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" textTransform={"uppercase"}>
                    {c.days[c.days.length - 1].dateString}
                  </Typography>
                </td>
                <td>
                  <Button
                    onClick={() => navigate(`/attendance/examination/${c._id}`)}
                    size="small"
                  >
                    view
                  </Button>
                </td>
                <td>
                  <DeleteData id={c._id} getData={viewSchedules} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal size="lg" show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>Add Examination Schedule</Modal.Title>
        </Modal.Header>
        <form onSubmit={createSchedule}>
          <Modal.Body>
            <div>
              <div className="">
                <Typography variant="overline">
                  Examination & DURATION
                </Typography>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    {activeExamination && (
                      <Typography textTransform={"uppercase"} variant="h6">
                        {activeExamination.title}
                      </Typography>
                    )}
                  </div>
                  <div className="col-lg-3 mb-4">
                    <TextField
                      fullWidth
                      label="Number of days"
                      type="number"
                      onChange={(e) => {
                        const data = [];
                        for (let i = 0; i < e.target.value; i++) {
                          data.push({ day: `Day ${i + 1}` });
                        }
                        setDays(data);
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <Typography variant="overline">DATE SELECTION</Typography>
                  <div className="d-flex flex-wrap">
                    {days.map((c, i) => (
                      <div key={i} className="mb-3 col-lg-4 me-2 mb-2">
                        <div className="mb-4">
                          <Chip label={c.day} />
                        </div>
                        <DatePicker
                          label="Select exam date"
                          sx={{ width: "100%" }}
                          onChange={(e) => {
                            setDays(
                              days.map((obj, index) => {
                                if (i === index) {
                                  return {
                                    ...obj,
                                    dateString: new Date(e).toDateString(),
                                  };
                                }
                                return obj;
                              })
                            );
                          }}
                          disablePast
                          format="LL"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              onClick={createSchedule}
              variant="contained"
              color="success"
              type="submit"
              loading={loading}
            >
              Create Schedule
            </LoadingButton>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default ExaminationAttendance;

function DeleteData({ id, getData }) {
  const [loading, setLoading] = useState(false);

  const { setAlertData } = useContext(AlertContext);
  const deleteData = async () => {
    Swal.fire({
      icon: "question",
      title: "Delete Data",
      text: "Are you sure you want to delete the data attached to this record?",
      showCancelButton: true,
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        const { data } = await httpService.get(`attendance/deletedata/${id}`);
        if (data) {
          setAlertData({ open: true, message: data, severity: "success" });
        }
        getData();
      }
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={15} />
      ) : (
        <IconButton onClick={deleteData}>
          <Delete />
        </IconButton>
      )}
    </>
  );
}
