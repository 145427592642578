import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { Table } from "react-bootstrap";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";
import Swal from "sweetalert2";
import { Delete, Person } from "@mui/icons-material";

export default function UsersPage() {
  const [userData, setUserData] = useState({ role: "viewer" });
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const { setAlertData } = useContext(AlertContext);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const getUsers = async () => {
    const { data } = await httpService("auth/viewaccounts");

    if (data) {
      setAccounts(data);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const addUser = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "Create User?",
      text: "Do you wish to create this user?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.post(
          "auth/adduser",
          userData
        );

        if (data) {
          getUsers();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" fontWeight={700}>
          USERS
        </Typography>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-8">
            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((c, i) => (
                  <tr key={i}>
                    <td>
                      <Typography>{i + 1}</Typography>
                    </td>
                    <td>
                      <Typography>{c.firstName}</Typography>
                    </td>
                    <td>
                      <Typography>{c.lastName}</Typography>
                    </td>
                    <td>
                      <Typography>{c.username}</Typography>
                    </td>
                    <td>
                      <Typography>{c.role}</Typography>
                    </td>
                    <td>
                      <DeleteAccount id={c._id} getData={getUsers} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-4 bg-light p-3">
            <Typography color="GrayText">Create User</Typography>

            <form onSubmit={addUser}>
              <div className="mt-3 mb-3">
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  onChange={handleChange}
                  name="firstName"
                />
              </div>
              <div className="mb-3">
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  onChange={handleChange}
                  name="lastName"
                />
              </div>
              <div className="mb-3">
                <TextField
                  fullWidth
                  required
                  label="Username"
                  onChange={handleChange}
                  name="username"
                />
              </div>
              <div className="mb-3">
                <TextField
                  fullWidth
                  required
                  label="Password"
                  onChange={handleChange}
                  name="password"
                />
              </div>
              <LoadingButton
                type="submit"
                loading={loading}
                startIcon={<Person />}
                loadingPosition="start"
                variant="contained"
              >
                create user
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function DeleteAccount({ id, getData }) {
  const [loading, setLoading] = useState(false);

  const { setAlertData } = useContext(AlertContext);

  const deleteAccount = () => {
    Swal.fire({
      icon: "question",
      title: "Delete Account",
      text: "Do you wish to delete this account?",
      showCancelButton: true,
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        const { data, error } = await httpService.delete(
          `auth/deleteaccount/${id}`
        );

        if (data) {
          getData();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });
      }
      setLoading(false);
    });
  };

  return (
    <LoadingButton onClick={deleteAccount} startIcon={<Delete />} color="error">
      delete
    </LoadingButton>
  );
}
