import React from "react";
import { Route, Routes } from "react-router-dom";
import CandidatesList from "./CandidatesList";
import NotFound from "../NotFound";
import SearchCandidate from "./SearchCandidate";
import SyncCandidate from "./SyncCandidate";

const privateRoutes = [
  { path: "/:id/list", component: CandidatesList },
  { path: "/search", component: SearchCandidate },
  { path: "/sync", component: SyncCandidate },
  //   { path: "/search", component: SearchCandidate },
  { path: "*", component: NotFound },
];

export default function CandidatesRoutes() {
  return (
    <div>
      <Routes>
        {privateRoutes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </div>
  );
}
