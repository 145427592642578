import React from "react";
import { useSearchParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { useEffect } from "react";
import { Typography, LinearProgress } from "@mui/material";
import { Table } from "react-bootstrap";
import { useState } from "react";

function AttendanceReport() {
  const [route] = useSearchParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const day = route.get("day");
  const attendance = route.get("attendance");
  const getRecords = async () => {
    setLoading(true);
    const { data } = await httpService.get(
      `attendance/dailyreportfull?dateRecorded=${day}&attendance=${attendance}`
    );

    if (data) {
      setRecords(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getRecords();
  }, []);
  return (
    <div>
      <div className="mb-3">
        <Typography variant="h6">ATTENDANCE REPORT</Typography>
      </div>
      {loading && (
        <div className="col-lg-4">
          <LinearProgress />
        </div>
      )}
      <div>
        <Table striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Examination Number</th>
              <th>Program</th>
              <th>School</th>
              <th>State</th>
              <th>Centre</th>
              <th>Time Logged</th>
            </tr>
          </thead>
          <tbody>
            {records.map((c, i) => (
              <tr key={i}>
                <td>
                  <Typography>{i + 1}</Typography>
                </td>
                <td>
                  <Typography
                    textTransform={"capitalize"}
                  >{`${c.firstName} ${c.middleName} ${c.lastName}`}</Typography>
                </td>
                <td>
                  <Typography>{c.examNumber}</Typography>
                </td>{" "}
                <td>
                  <Typography>{c.program}</Typography>
                </td>
                <td>
                  <Typography>{c.school}</Typography>
                </td>
                <td>
                  <Typography>{c.state}</Typography>
                </td>
                <td>
                  <Typography>{c.centre.centreId}</Typography>
                </td>
                <td>
                  <Typography>
                    {new Date(c.timeRecorded).toLocaleTimeString()}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default AttendanceReport;
