import {
  Button,
  CircularProgress,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { httpService } from "../../httpService";
import { Table, Modal } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { Delete, Save, ExpandLess, ExpandMore } from "@mui/icons-material";
import { AlertContext } from "../../contexts/AlertContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStaffSnake,
  faDisplay,
  faDatabase,
  faArrowRight,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
export default function AllProgrammes() {
  const [programmes, setProgrammes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programme, setProgramme] = useState({});
  const [creating, setCreating] = useState(false);
  const { setAlertData } = useContext(AlertContext);

  const getData = async () => {
    setLoading(true);
    const { data, error } = await httpService("programme/all");
    if (data) {
      setProgrammes(data);
    }
    if (error) setAlertData({ message: error, open: true, severity: "error" });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const createSubject = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "Create new programme?",
      text: "Do you wish to create this new programme",
      showCancelButton: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        setCreating(true);
        const { data, error } = await httpService.post(
          "programme/create",
          programme
        );
        if (data) {
          getData();
          setAlertData({ message: data, severity: "success", open: true });
        }
        if (error) {
          setAlertData({ message: error, severity: "error", open: true });
        }
        setCreating(false);
      }
    });
  };

  const handleChange = (e) => {
    setProgramme({ ...programme, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="mb-3">
        <Typography variant="h4" fontWeight={600}>
          ALL PROGRAMMES
        </Typography>
      </div>
      {loading && <CircularProgress />}
      <div className="row">
        <div className="col-lg-8">
          <Table borderless striped>
            <thead className="bg-dark text-white">
              <tr>
                <th>S/N</th>
                <th>Programme</th>
                <th>Code</th>
                <th>Abbreviation</th>
                <th>Papers</th>
                <th>Number of Questions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {programmes.length > 0 ? (
                  <>
                    {programmes.map((c, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.name}
                          </Typography>
                          <Button
                            onClick={() =>
                              navigate(`/programmes/questionbank/${c._id}`)
                            }
                          >
                            question banks
                          </Button>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.code}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.abbreviation}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.numberOfPapers}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.numberOfQuestions}
                          </Typography>
                        </td>

                        <td>
                          <ActionButton programme={c} getData={getData} />
                          {/* <DeleteSubject id={c._id} getData={getData} /> */}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center">
                      <Typography variant="h6">No data found</Typography>
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </div>
        <div className="col-lg-4  rounded-3 border-start">
          <div className="mb-3">
            <div className="mb-5">
              <Typography variant="h6">Create a new programme</Typography>
            </div>
            <form onSubmit={createSubject}>
              <div className="mb-4">
                <TextField
                  fullWidth
                  required
                  name="name"
                  label="Programme name"
                  onChange={handleChange}
                />
              </div>{" "}
              <div className="mb-4">
                <TextField
                  label="Programme code"
                  fullWidth
                  name="code"
                  required
                  onChange={handleChange}
                />
              </div>{" "}
              <div className="mb-4">
                <TextField
                  label="Program Abbreviation"
                  fullWidth
                  required
                  name="abbreviation"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <TextField
                  label="Number of papers"
                  fullWidth
                  required
                  name="numberOfPapers"
                  type="number"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <TextField
                  label="Number of questions"
                  fullWidth
                  required
                  name="numberOfQuestions"
                  type="number"
                  onChange={handleChange}
                />
              </div>
              <div>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<Save />}
                  loading={creating}
                >
                  Create
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionButton(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const [show, setShow] = useState(false);
  const [programmeData, setProgrammeData] = useState(props.programme);
  const [creating, setCreating] = useState(false);
  const handleShow = () => setShow(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const deleteSubject = () => {
    Swal.fire({
      icon: "question",
      title: "Delete Programme",
      text: "Deleting a programme will consequently delete all the question banks attached to this particular programme. Are you sure you wish to proceed?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.delete(
          `programme/delete/${props.programme._id}`
        );
        if (data) {
          props.getData();
          setAlertData({ message: data, severity: "success", open: true });
        }
        if (error) {
          setAlertData({ message: error, severity: "error", open: true });
        }
        setLoading(false);
      }
    });
  };

  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    setProgrammeData({ ...programmeData, [e.target.name]: e.target.value });
  };

  const createSubject = async (e) => {
    e.preventDefault();
    setCreating(true);
    const { data, error } = await httpService.patch(
      `programme/edit/${props.programme._id}`,
      programmeData
    );

    if (data) {
      handleClose();
      props.getData();
      setAlertData({ open: true, message: data, severity: "success" });
    }
    if (error) setAlertData({ open: true, message: error, severity: "error" });
    setCreating(false);
  };
  return (
    <>
      <List>
        <ListItemButton className="mb-3" onClick={handleClick}>
          <ListItemText primary="Action" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={handleShow}
              sx={{ ":hover": { color: "GrayText" } }}
            >
              <ListItemText primary="Edit" />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                navigate(`/programmes/osce/${props.programme._id}`)
              }
              sx={{ ":hover": { color: "GrayText" } }}
            >
              <ListItemText primary="OSCE" />
            </ListItemButton>
            <ListItemButton
              color="error"
              onClick={deleteSubject}
              sx={{ ":hover": { color: "GrayText" } }}
            >
              <ListItemText primary="Delete" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>EDIT PROGRAM</Modal.Header>
        <form onSubmit={createSubject}>
          <Modal.Body>
            <div className="mb-4">
              <TextField
                fullWidth
                required
                name="name"
                label="Programme name"
                onChange={handleChange}
                value={programmeData.name}
              />
            </div>{" "}
            <div className="mb-4">
              <TextField
                label="Programme code"
                fullWidth
                name="code"
                required
                onChange={handleChange}
                value={programmeData.code}
              />
            </div>{" "}
            <div className="mb-4">
              <TextField
                label="Program Abbreviation"
                fullWidth
                required
                name="abbreviation"
                onChange={handleChange}
                value={programmeData.abbreviation}
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Number of papers"
                fullWidth
                required
                name="numberOfPapers"
                type="number"
                onChange={handleChange}
                value={programmeData.numberOfPapers}
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Number of questions"
                fullWidth
                required
                name="numberOfQuestions"
                type="number"
                onChange={handleChange}
                value={programmeData.numberOfQuestions}
              />
            </div>
            <div></div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton type="submit" variant="contained" loading={creating}>
              update
            </LoadingButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
