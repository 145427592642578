import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import ExaminationAttendance from "./ExaminationAttendance";
import ExaminationReport from "./ExaminationReport";
import AttendanceReport from "./AttendanceReport";
import CentreReport from "./CentreReport";
function AttendanceRoutes() {
  const privateRoutes = [
    { path: "/", component: ExaminationAttendance },
    { path: "/examination/:id", component: ExaminationReport },
    { path: "/attendancereport", component: AttendanceReport },
    { path: "/centrereport", component: CentreReport },
    { path: "*", component: NotFound },
  ];
  return (
    <div>
      <Routes>
        {privateRoutes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </div>
  );
}

export default AttendanceRoutes;
