import React, { useEffect, useState } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { ProgressBar, Table } from "react-bootstrap";
function CentreReport() {
  const [getRoute] = useSearchParams();
  const [examcentres, setExamCentres] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actualExam, setActualExam] = useState("");

  const examination = getRoute.get("examination");
  const attendance = getRoute.get("attendance");
  const day = getRoute.get("day");

  /**
   * Get the centres involved
   */
  const getCentresInvolved = async () => {
    setLoading(true);
    const { data } = await httpService(`attendance/examcentres/${examination}`);
    if (data) {
      setActualExam(data.examination._id);
      setExamCentres(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCentresInvolved();
  }, []);
  return (
    <div>
      <div className="mb-3">
        <Typography>CENTRE REPORT</Typography>
      </div>
      {loading && (
        <div className="col-lg-3">
          <LinearProgress color="success" />
        </div>
      )}
      {examcentres && (
        <div>
          <div className="col-lg-4 mb-4 alert alert-light">
            <Typography variant="caption">Title</Typography>
            <Typography textTransform={"uppercase"} variant="h6">
              {examcentres.examination.title}
            </Typography>
            <Typography variant="body2">{day}</Typography>
          </div>

          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Centre ID</th>
                <th>Attendance Marked</th>
                <th>Expected</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {examcentres.centres.map((c, i) => (
                <tr key={i}>
                  <td>
                    <Typography>{i + 1}</Typography>
                  </td>
                  <td>
                    <Typography>{c.centreId}</Typography>
                  </td>
                  <CentreProgress
                    centre={c._id}
                    day={day}
                    examination={examination}
                    actualExam={actualExam}
                    attendance={attendance}
                  />
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default CentreReport;

function CentreProgress({ centre, day, examination, attendance, actualExam }) {
  const [record, setRecord] = useState(0);
  const [expectedCandidates, setExpectedCandidates] = useState(0);
  const getData = async () => {
    const { data } = await httpService(
      `attendance/dailyreportcount?centre=${centre}&dateRecorded=${day}&attendance=${attendance}`
    );
    if (data) {
      setRecord(data.count);
    }
  };

  const getExpectedCandidates = async () => {
    const { data } = await httpService(`attendance/expectedcandidates`, {
      headers: { examination: actualExam, centre: centre },
    });
    if (data) {
      setExpectedCandidates(data.candidates);
    }
  };

  useEffect(() => {
    getData();
    getExpectedCandidates();
    const timer = setInterval(() => {
      getData();
    }, 60 * 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <>
      <td>{record}</td>
      <td>{expectedCandidates}</td>
      <td>
        <ProgressBar
          animated
          variant="success"
          now={Math.floor((record / expectedCandidates) * 100)}
          label={`${Math.floor((record / expectedCandidates) * 100)}%`}
        />
      </td>
    </>
  );
}
