import {
  Button,
  Fade,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  Grow,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { httpService } from "../../httpService";
import { AlertContext } from "../../contexts/AlertContext";
import { Modal, Table } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { Delete, ToggleOff, ToggleOn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function AllExaminations() {
  const [loading, setLoading] = useState(false);
  const [programmes, setProgrammes] = useState([]);
  const [examinations, setExaminations] = useState([]);
  const [show, setShow] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const [creating, setCreating] = useState(false);

  const [examination, setExamination] = useState({ title: "", programmes: [] });

  const navigate = useNavigate();
  const getProgrammes = async () => {
    setLoading(true);
    const { data, error } = await httpService("programme/all");
    if (data) {
      setProgrammes(data);
    }
    if (error) setAlertData({ message: error, open: true, severity: "error" });
    setLoading(false);
  };
  const getExaminations = async () => {
    setLoading(true);
    const { data, error } = await httpService("examination/all");
    if (data) {
      setExaminations(data);
    }
    if (error) setAlertData({ message: error, open: true, severity: "error" });
    setLoading(false);
  };

  useEffect(() => {
    getProgrammes();
    getExaminations();
  }, []);

  const handleChecked = (e) => {
    //check if it exists
    if (e.target.checked) {
      const programme = examination.programmes.find(
        (c) => c === e.target.value
      );

      if (!programme)
        setExamination({
          ...examination,
          programmes: [...examination.programmes, e.target.value],
        });
    }
    if (!e.target.checked) {
      const newProgrammes = examination.programmes.filter(
        (c) => c !== e.target.value
      );

      setExamination({
        ...examination,
        programmes: newProgrammes,
      });
    }
  };

  const createExamination = (e) => {
    e.preventDefault();

    if (examination.programmes.length === 0)
      return Swal.fire({
        icon: "warning",
        title: "No programme selected",
        text: "Please select a programme",
      });
    Swal.fire({
      icon: "question",
      title: "Create new Examination",
      text: "Do you wish to create this new examination",
      showCancelButton: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        setCreating(true);
        const { data, error } = await httpService.post(
          "examination/create",
          examination
        );

        if (data) {
          setAlertData({ open: true, message: data, severity: "success" });
          getExaminations();
          setExamination({ title: "", programmes: [] });
          setShow(false);
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setCreating(false);
      }
    });
  };
  return (
    <div>
      <div className="mb-3">
        <div className="mb-4">
          <Typography variant="h4" fontWeight={600}>
            EXAMINATION CONTROL
          </Typography>
        </div>
        {loading && <LinearProgress />}
        <div className="col-lg-4">
          <div className="mb-4">
            <Button onClick={() => setShow(!show)}>
              {show ? "hide form" : "create new examination"}
            </Button>
          </div>

          <div>
            {show && (
              <Grow
                in={show}
                style={{ transformOrigin: "0 0 0" }}
                {...(show ? { timeout: 500 } : {})}
              >
                <form onSubmit={createExamination}>
                  <div className="mb-3">
                    <TextField
                      fullWidth
                      label="Title"
                      required
                      onChange={(e) =>
                        setExamination({
                          ...examination,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-3 programmeCheckbox">
                    <FormGroup>
                      {programmes.map((c, i) => (
                        <FormControlLabel
                          key={i}
                          value={c._id}
                          control={<Checkbox />}
                          label={c.name.toUpperCase()}
                          onChange={handleChecked}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="mb-3">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="success"
                      loading={creating}
                    >
                      Create examination
                    </LoadingButton>
                  </div>
                </form>
              </Grow>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <Table borderless striped>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Title</th>
              <th>Activate</th>
              <th>Programmes</th>
              <th>Paper Types</th>
              <th>Candidates</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <>
              {examinations.length > 0 ? (
                <>
                  {examinations.map((c, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <Typography textTransform={"uppercase"}>
                          {c.title}
                        </Typography>
                      </td>
                      <td>
                        <ToggleActivation
                          examination={c}
                          viewExaminations={getExaminations}
                        />
                      </td>
                      <td className="col-lg-6">
                        {c.programmes.length > 0 ? (
                          <>
                            {c.programmes.map((d, j) => (
                              <Chip
                                className="me-1 mb-1"
                                key={j}
                                label={
                                  <Typography textTransform={"capitalize"}>
                                    {d.name}
                                  </Typography>
                                }
                              />
                            ))}
                          </>
                        ) : (
                          <Typography>No programmes selected yet</Typography>
                        )}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            navigate(`/examination/papertype/${c._id}`)
                          }
                        >
                          view
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => navigate(`/candidates/${c._id}/list`)}
                        >
                          view candidates
                        </Button>
                      </td>
                      <td>
                        <DeleteExam
                          examination={c}
                          viewExams={getExaminations}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  {" "}
                  <td colSpan={12} className="text-center">
                    <h5>No exams found</h5>
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

function ToggleActivation({ examination, viewExaminations }) {
  const [activating, setActivating] = useState(false);

  const { setAlertData } = useContext(AlertContext);

  const toggleActivate = (action) => {
    Swal.fire({
      icon: "question",
      title: `${action} this exam?`,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setActivating(true);
        const { data, error } = await httpService.patch(
          `examination/${examination._id}/toggleactivate`,
          { action }
        );

        if (data) {
          viewExaminations();
          setAlertData({ open: true, severity: "success", message: data });
        }
        if (error) {
          setAlertData({ open: true, severity: "error", message: error });
        }
        setActivating(false);
      }
    });
  };
  return (
    <>
      {examination.active ? (
        <LoadingButton
          color="error"
          loading={activating}
          loadingPosition="end"
          endIcon={<ToggleOff />}
          onClick={() => toggleActivate("deactivate")}
        >
          deactivate
        </LoadingButton>
      ) : (
        <LoadingButton
          loading={activating}
          endIcon={<ToggleOn />}
          onClick={() => toggleActivate("activate")}
        >
          activate
        </LoadingButton>
      )}
    </>
  );
}

function DeleteExam({ examination, viewExams }) {
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [examName, setExamName] = useState("");

  const { setAlertData } = useContext(AlertContext);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const deleteExamination = async () => {
    setDeleting(true);
    const { data, error } = await httpService(
      `examination/deleteexam/${examination._id}`
    );

    if (data) {
      viewExams();
      setAlertData({ severity: "success", message: data, open: true });
    }

    if (error) setAlertData({ severity: "error", message: error, open: true });

    setDeleting(false);

    handleClose();
  };
  return (
    <>
      <IconButton onClick={handleShow}>
        <Delete />
      </IconButton>

      <Modal size="lg" show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ textTransform: "uppercase" }}
          >
            {examination.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Please Note</h5>
          <p>
            You are about to delete an examination. This will delete the
            following alongside:
          </p>

          <p>1. All the candidates registered for this examination.</p>
          <p>2. All the examination sessions created for this examination.</p>
          <p>3. All the candidates responses for this examination.</p>
          <p>4. All the centres reports for this examination.</p>
          <p>5. The examination itself.</p>

          <div className="mt-3">
            <p>I have read the above and understand what I'm doing.</p>
            <div className="col-lg-8">
              <TextField
                fullWidth
                label="Exam name"
                onChange={(e) => setExamName(e.target.value.toLowerCase())}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handleClose}>
            close
          </Button>
          <LoadingButton
            onClick={deleteExamination}
            loadingPosition="center"
            loading={deleting}
            disabled={examName !== examination.title}
            color="error"
            variant="contained"
          >
            Delete exam
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
