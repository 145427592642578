import { Avatar, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { Table } from "react-bootstrap";

export default function MonitoringDashboard() {
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paperType, setPaperType] = useState(null);
  const [summary, setSummary] = useState({
    downloaded: 0,
    uploaded: 0,
    responsesRecieved: 0,
    totalResponses: 0,
  });

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`centres/monitoring/${id}`);
    if (data) {
      setReports(data.reports);
      setPaperType(data.paperType);

      let totalDownload = 0;
      let totalUpload = 0;
      let totalResponses = 0;

      data.reports.forEach((c) => {
        if (c.timeDownloaded) totalDownload += 1;
        if (c.timeUploaded) totalUpload += 1;
        if (c.totalResponsesRecieved)
          totalResponses += c.totalResponsesRecieved;
      });

      setSummary({
        ...summary,
        downloaded: totalDownload,
        uploaded: totalUpload,
        totalResponses,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="mb-4">
        <Typography
          variant="h4"
          gutterBottom
          textTransform={"uppercase"}
          fontWeight={600}
        >
          Monitoring Dashboard
        </Typography>
        {paperType && (
          <div>
            {" "}
            <Typography
              variant="body1"
              textTransform={"uppercase"}
              gutterBottom
              color={"GrayText"}
            >
              {paperType.examination.title}
            </Typography>
            <Typography
              color={"GrayText"}
              variant="body2"
              textTransform={"uppercase"}
            >
              {paperType.title}
            </Typography>
          </div>
        )}
      </div>
      {loading && <LinearProgress />}
      <div>
        <div className="mt-3 mb-3">
          <div className="row">
            <div className="col-lg-3 alert alert-info shadow-sm me-2 mb-2">
              <Typography>Centres Downloaded</Typography>
              <div className="mt-1">
                <Typography variant="h4">
                  {summary.downloaded}/{reports.length}
                </Typography>
              </div>
            </div>
            <div className="col-lg-3 alert alert-success shadow-sm me-2 mb-2">
              <Typography>Centres Uploaded</Typography>
              <div className="mt-1">
                <Typography variant="h4">
                  {summary.uploaded}/{reports.length}
                </Typography>
              </div>
            </div>
            <div className="col-lg-3 alert alert-primary shadow-sm me-2 mb-2">
              <Typography>Total Responses received</Typography>
              <div className="mt-1">
                <Typography variant="h4">{summary.totalResponses}</Typography>
              </div>
            </div>
          </div>
        </div>
        <Table striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Centre</th>
              <th>Time Downloaded</th>
              <th>Time Uploaded</th>
              <th>Time Started</th>
              <th>Time Ended</th>
              <th>Assigned</th>
              <th>Started</th>
              <th>Submitted</th>
              <th>Responses Receieved</th>
            </tr>
          </thead>
          <tbody>
            {reports.length > 0 ? (
              <>
                {reports
                  .sort((a, b) =>
                    a.centre.centreId > b.centre.centreId
                      ? 1
                      : b.centre.centreId > a.centre.centreId
                      ? -1
                      : 0
                  )
                  .map((c, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <Typography>{c.centre.centreId}</Typography>
                      </td>
                      <td>
                        {c.timeDownloaded ? (
                          <>
                            <div className="mb-1">
                              <Typography>
                                {new Date(c.timeDownloaded).toDateString()}{" "}
                              </Typography>
                              {new Date(c.timeDownloaded).toLocaleTimeString()}{" "}
                            </div>
                          </>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </td>
                      <td>
                        {c.timeUploaded ? (
                          <div className="mb-1">
                            <Typography>
                              {new Date(c.timeUploaded).toDateString()}{" "}
                            </Typography>
                            {new Date(c.timeUploaded).toLocaleTimeString()}{" "}
                          </div>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </td>
                      <td>
                        {c.timeStarted ? (
                          <div className="mb-1">
                            <Typography>
                              {new Date(c.timeStarted).toDateString()}{" "}
                            </Typography>
                            {new Date(c.timeStarted).toLocaleTimeString()}{" "}
                          </div>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </td>
                      <td>
                        {c.timeEnded ? (
                          <div className="mb-1">
                            <Typography>
                              {new Date(c.timeEnded).toDateString()}{" "}
                            </Typography>
                            {new Date(c.timeEnded).toLocaleTimeString()}{" "}
                          </div>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </td>
                      <td>
                        <Typography>{c.totalAssigned}</Typography>
                      </td>
                      <td>
                        <Typography>{c.totalStarted}</Typography>
                      </td>
                      <td>
                        <Typography>{c.totalSubmitted}</Typography>
                      </td>
                      <td>
                        <ResponseRecieved
                          centre={c.centre._id}
                          paperType={c.paperType}
                          loading={loading}
                        />
                        {/* <Typography>{c.totalResponsesRecieved}</Typography> */}
                      </td>
                    </tr>
                  ))}
              </>
            ) : (
              <tr>
                <td colSpan={12} className="text-center">
                  No data{" "}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

function ResponseRecieved({ centre, paperType, loading }) {
  const [responses, setResponses] = useState(0);
  const navigate = useNavigate();
  const getData = async () => {
    const { data, error } = await httpService.post(
      "results/centreresponsecount",
      {
        centre,
        paperType,
      }
    );

    if (data) {
      setResponses(data.count);
    }
  };

  useEffect(() => {
    getData();
  }, [loading]);

  return (
    <IconButton
      onClick={() =>
        navigate(
          `/examination/papertype/centreresult?centre=${centre}&papertype=${paperType}`
        )
      }
    >
      <Avatar>{responses}</Avatar>
    </IconButton>
  );
}
