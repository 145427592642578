import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { Button, CircularProgress, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

export default function CentresCandidates() {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [session1, setSession1] = useState(0);

  const [session2, setSession2] = useState(0);
  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.get(
      `servermanager/centrecandidates/${id}`
    );

    if (data) {
      setReport(data);

      let firstSession = 0;

      let secondSession = 0;

      data.candidates.forEach((c) => {
        if (c.session === "session 1") firstSession++;
        else if (c.session === "session 2") secondSession++;
      });

      setSession1(firstSession);

      setSession2(secondSession);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      Centres Candidates
      <div className="mt-4 mb-4">
        {loading && <CircularProgress />}
        {report && (
          <div>
            <div className="">
              <Typography color={"skyblue"} variant="h6" fontWeight={700}>
                {report.centreName}
              </Typography>
              <Typography color="GrayText">Centre {report.centreId}</Typography>
            </div>
            <div className="mt-1 mb-1">
              <Button startIcon={<ArrowBackIos />} onClick={() => navigate(-1)}>
                Go back
              </Button>
            </div>
            <div className="mt-4">
              <div className="mt-2 mb-2">
                <div className="row">
                  <div className="col-lg-3 alert alert-info me-2">
                    <Typography variant="body1" component="p">
                      First Session: <strong>{session1}</strong>
                    </Typography>
                  </div>
                  <div className="col-lg-3 alert alert-danger">
                    <Typography variant="body1" component="p">
                      Second Session: <strong>{session2}</strong>
                    </Typography>
                  </div>
                </div>
              </div>
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Examination Number</th>
                    <th>School</th>
                    <th>Session</th>
                  </tr>
                </thead>
                <tbody>
                  {report.candidates.map((c, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <Typography>
                          {c.firstName} {c.lastName} {c.middleName}
                        </Typography>
                      </td>
                      <td>
                        <Typography textTransform={"uppercase"}>
                          {c.registrationNumber}
                        </Typography>
                      </td>
                      <td>
                        <Typography textTransform={"uppercase"}>
                          {c.school}
                        </Typography>
                      </td>
                      <td>
                        <Typography>{c.session}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
