import {
  TextField,
  Typography,
  Chip,
  Link,
  LinearProgress,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import React, { useEffect, useState, useContext } from "react";
import { Delete, People, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import MyPagination from "../../components/MyPagination";
import { Badge, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../contexts/AlertContext";
import { Input } from "mdb-ui-kit";
export default function CandidatesList() {
  const { id } = useParams();
  const [examination, setExamination] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [limit, setLimit] = useState(0);

  const [totalCandidates, setTotalCandidates] = useState(0);
  const [results, setResults] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [assigned, setAssigned] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [file, setFile] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { setAlertData } = useContext(AlertContext);

  const getExamination = async () => {
    setLoading(true);

    const { data } = await httpService(`examination/view/${id}`);
    if (data) {
      setExamination(data);
    }
    setLoading(false);
  };

  const getCandidates = async () => {
    setFetching(true);
    const { data } = await httpService(
      `candidates/${id}/view?page=${1}&limit=${50}`
    );

    if (data) {
      setResults(data.results);
      setStartIndex(data.startIndex);
      setTotalCandidates(data.allResultsLength);
      setAssigned(data.assigned);
      setUnassigned(data.unassigned);
    }
    setFetching(false);
  };

  useEffect(() => {
    getExamination();
    getCandidates();
  }, []);

  const createDummyCandidates = () => {
    Swal.fire({
      icon: "question",
      title: `Create dummy candidates?`,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCreating(true);

        const { data, error } = await httpService.post(
          `candidates/createdummy/${id}`,
          { limit }
        );

        if (data) {
          getCandidates();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setCreating(false);
      }
    });
  };
  const uploadFile = async () => {
    setUploading(true);
    const formData = new FormData();

    formData.append("candidatefile", file, file.name);
    const { data, error } = await httpService.post(
      `candidates/uploadcandidates/${id}`,
      formData
    );

    if (data) {
      getCandidates();
      setAlertData({ open: true, message: data, severity: "success" });
    }

    if (error) setAlertData({ open: true, message: error, severity: "error" });
    getCandidates();
    setUploading(false);
  };
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const deleteCandidates = () => {
    Swal.fire({
      icon: "question",
      title: "Delete Candidates",
      text: "Are you sure you want to delete these candidates?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleting(true);
        const { data } = await httpService.delete(`candidates/delete/${id}`);
        if (data) {
          getCandidates();
        }
        setDeleting(false);
      }
    });
  };
  return (
    <div>
      {" "}
      {loading && <LinearProgress />}
      {examination && (
        <div>
          <div className="mb-4">
            <Typography
              textTransform={"uppercase"}
              variant="h4"
              fontWeight={600}
              gutterBottom
            >
              {examination.title}
            </Typography>

            <Typography>Candidates List</Typography>
          </div>
          <div className="col-lg-4 mb-3">
            <label for="formFile" className="form-label">
              <Typography variant="overline">
                Select an excel or csv file
              </Typography>
            </label>
            <input
              class="form-control"
              type="file"
              id="formFile"
              accept=".xlsx,.csv"
              onChange={handleChange}
            />
            <div className="mt-1">
              {file && (
                <div>
                  {/* <Typography gutterBottom textTransform={"uppercase"}>
                    {file.name}
                  </Typography> */}
                  <LoadingButton
                    loading={uploading}
                    onClick={uploadFile}
                    startIcon={<FontAwesomeIcon icon={faUpload} />}
                  >
                    Upload file
                  </LoadingButton>
                </div>
              )}
            </div>
          </div>
          {/* <div className="d-flex justify-content-end">
            <div className="col-lg-3">
              <Typography gutterBottom>Dummy Candidates</Typography>
              <form onSubmit={createDummyCandidates}>
                <div className="mb-4">
                  <TextField
                    type="number"
                    min={1}
                    fullWidth
                    required
                    label="Limit"
                    onChange={(e) =>
                      setDummyData({
                        ...dummyData,
                        limit: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    required
                    label="Exam number prefix"
                    onChange={(e) =>
                      setDummyData({ ...dummyData, prefix: e.target.value })
                    }
                  />
                </div>
                <LoadingButton loading={creating} type="submit">
                  create
                </LoadingButton>
              </form>
            </div>
          </div> */}
          <div className="">
            <div className="row mb-4">
              <div
                className="col-lg-3 p-3"
                style={{ backgroundColor: "#fdb874" }}
              >
                <Typography>
                  Total Candidates: {totalCandidates.toLocaleString()}
                </Typography>
              </div>
              <div
                className="col-lg-3 p-3"
                style={{ backgroundColor: "#b088ad", color: "white" }}
              >
                <Typography>
                  Unassigned Candidates: {unassigned.toLocaleString()}
                </Typography>
              </div>
              <div
                className="col-lg-3 p-3"
                style={{ backgroundColor: "#1d2b67", color: "white" }}
              >
                <Typography>
                  Assigned Candidates: {assigned.toLocaleString()}
                </Typography>
              </div>
            </div>
            <div className="mt-2 mb-2">
              <Typography variant="caption" color="Highlight">
                Create dummy candidates across the selected centres
              </Typography>
              <div className="mt-3">
                <div className="col-lg-4">
                  <TextField
                    fullWidth
                    label="Number of candidates per centre"
                    type="number"
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <LoadingButton
                    onClick={createDummyCandidates}
                    startIcon={<People />}
                    loading={creating}
                    loadingPosition="start"
                  >
                    create dummy candidates
                  </LoadingButton>
                </div>
              </div>
              {/* <Link underline="none" href={`/candidates/performsync/${id}`}>
                PERFORM SYNC OPERATION
              </Link> */}
            </div>
            <div className="mt-2 mb-2 text-end">
              <LoadingButton
                color="error"
                onClick={deleteCandidates}
                loading={deleting}
                loadingPosition="start"
                startIcon={<Delete />}
              >
                delete all candidates
              </LoadingButton>
            </div>
            {fetching && <CircularProgress />}
            <Table borderless striped>
              <thead>
                <th>S/N</th>
                <th>Name</th>
                <th>Registration Number</th>
                <th>Programme</th>
                <th>Centre</th>
                <th>Seat Number</th>
                <th>Session</th>
                {/* <th>Error</th> */}
              </thead>
              <tbody>
                {results.length > 0 ? (
                  <>
                    {results.map((c, i) => (
                      <tr key={i}>
                        <td className="col-lg-1">
                          <Typography>{startIndex + i}</Typography>
                        </td>
                        <td>
                          <Typography>
                            {c.firstName} {c.middleName} {c.lastName}
                          </Typography>
                          {c.synccandidate && (
                            <Chip color="success" label="Synced Candidate" />
                          )}
                        </td>

                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.registrationNumber}
                          </Typography>
                        </td>
                        <td>
                          {c.programme && (
                            <Typography textTransform="capitalize">
                              {c.programme.name}
                            </Typography>
                          )}
                        </td>
                        <td>
                          {c.centre && (
                            <div>
                              <Typography>{c.centre.centreId}</Typography>
                              <Typography variant="caption">
                                {c.centre.state}
                              </Typography>
                            </div>
                          )}
                        </td>
                        <td>
                          <Typography>{c.seatNumber}</Typography>
                        </td>
                        <td>
                          <Typography>{c.session}</Typography>
                        </td>
                        {/* <td>
                          {c.errorMessage ? (
                            <Tooltip title={c.errorMessage} placement="top">
                              <Badge bg="danger">Has Error</Badge>
                            </Tooltip>
                          ) : (
                            <Badge bg="success">No Error</Badge>
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={12} className="mt-5 mb-5">
                      <Typography textAlign={"center"}>
                        NO DATA FOUND
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <MyPagination
            rootPath={`candidates/${id}/view?`}
            setResults={setResults}
            setStartIndex={setStartIndex}
          />
        </div>
      )}
    </div>
  );
}
