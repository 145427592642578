import { Typography } from "@mui/material";
import React from "react";

export default function NotFound() {
  return (
    <div>
      <div className=" mt-5 mb-5 container">
        <div className="mb-2">
          <Typography variant="h3" fontWeight={700} gutterBottom>
            404
          </Typography>
          <Typography>Page not found</Typography>
        </div>
      </div>
    </div>
  );
}
