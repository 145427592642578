import React from "react";
import { Chip, Typography, Button } from "@mui/material";
import { httpService } from "../httpService";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DailyReportCard({ day, examination }) {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const getData = async () => {
    const { data } = await httpService(
      `attendance/dailyreportcount?dateRecorded=${day.dateString}&attendance=${examination}`
    );

    if (data) {
      setCount(data.count);
    }
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="border-end">
      <Chip label={day.day} className="me-2" />
      <Typography variant="caption">{day.dateString}</Typography>

      <div className="mt-3">
        <Typography variant="h3">{count.toLocaleString()}</Typography>
      </div>
      <div>
        <Button
          onClick={() =>
            navigate(
              `/attendance/attendancereport?attendance=${examination}&day=${day.dateString}`
            )
          }
        >
          view report
        </Button>
        <Button
          color="error"
          onClick={() =>
            navigate(
              `/attendance/centrereport?attendance=${examination}&day=${day.dateString}`
            )
          }
        >
          centre report
        </Button>
      </div>
    </div>
  );
}

export default DailyReportCard;
