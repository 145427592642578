import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { httpService } from "../../httpService";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

export default function ListOfServers() {
  const tableRef = useRef(null);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService("servermanager/all");

    if (data) setServers(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {" "}
      <div className="mb-4">
        <Typography variant="h4" fontWeight={700}>
          SERVERS LIST
        </Typography>
      </div>
      {loading && <CircularProgress />}
      <div>
        <DownloadTableExcel
          filename="Server List"
          sheet="server list"
          currentTableRef={tableRef.current}
        >
          <Button variant="contained"> download list </Button>
        </DownloadTableExcel>
        <Table ref={tableRef} striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Server ID</th>
              <th>Password</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            {servers.map((c, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <Typography>{c.centreId}</Typography>
                </td>
                <td>
                  <Typography>{c.password}</Typography>
                </td>
                <td>
                  <Typography>{c.state}</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
