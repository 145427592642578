import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import ServersList from "./ServersList";
import CreateServer from "./CreateServer";
import ExamServers from "./ExamServers";
import ListOfServers from "./ListOfServers";
import ExamserversBreakdown from "./ExamserversBreakdown";
import CentresCandidates from "./CentresCandidates";

export default function ServersManager() {
  const routes = [
    { path: "/", component: ServersList },
    { path: "/createserver", component: CreateServer },
    { path: "/examservers/:id", component: ExamServers },
    { path: "/list", component: ListOfServers },
    { path: "/examserversbreakdown/:id", component: ExamserversBreakdown },
    { path: "/centrescandidates/:id", component: CentresCandidates },
    { path: "*", component: NotFound },
  ];
  return (
    <div>
      <Routes>
        {routes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </div>
  );
}
