import React from "react";
import { httpService } from "../../httpService";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { LinearProgress, Typography } from "@mui/material";
import DailyReportCard from "../../components/DailyReportCard";
function ExaminationReport() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data, error } = await httpService(`attendance/schedule/${id}`);
    if (data) {
      setSchedule(data);
    }
    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {loading && (
        <div className="col-lg-4">
          <LinearProgress />
        </div>
      )}

      {schedule && (
        <div>
          <div className="col-lg-4 mb-4">
            <Typography variant="h6" textTransform={"uppercase"}>
              {schedule.title}
            </Typography>
          </div>
          <div className="row">
            {schedule.days.map((c, i) => (
              <div key={i} className="col-lg-4 me-2 mb-2">
                <DailyReportCard day={c} examination={id} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExaminationReport;
