import { Stack, Typography } from "@mui/material";
import React from "react";
import parser from "html-react-parser";
import { Badge } from "react-bootstrap";
export default function QuestionBankCard({ question, index }) {
  function switchOptiontage(i) {
    switch (i) {
      case 0:
        return "A.";
      case 1:
        return "B.";
      case 2:
        return "C.";
      case 3:
        return "D.";
      case 4:
        return "E.";
      case 5:
        return "F.";
      case 6:
        return "G.";
      default:
        break;
    }
  }
  return (
    <div className="mb-3">
      <Typography fontWeight={700}>Question {index + 1}</Typography>
      <div className="mt-1 mb-1">{parser(question.question)}</div>
      <div>
        {question.options.map((c, i) => (
          <div>
            <Stack direction={"row"} spacing={2}>
              <div>{switchOptiontage(i)} </div>
              <div>{parser(c)}</div>
              <div>
                {question.correctAnswer === c && <Badge>CORRECT ANSWER</Badge>}
              </div>
            </Stack>
          </div>
        ))}
      </div>
    </div>
  );
}
