import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { httpService } from "../../httpService";
import parse from "html-react-parser";
import { LinearProgress, Stack } from "@mui/material";
import { Badge } from "react-bootstrap";
function Transcript() {
  const [query, setQuery] = useSearchParams();
  const candidate = query.get("candidate");
  const [transcript, setTranscript] = useState([]);
  const [loading, setLoading] = useState(false);
  const [candidateData, setCandidateData] = useState(null);

  const paperType = query.get("papertype");

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.post("candidates/gettranscript", {
      candidate,
      paperType,
    });

    if (data) {
      setCandidateData(data.candidate);
      setTranscript(data.transcript);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      Transcript
      <div className="mt-3">
        <div className="col-lg-4">{loading && <LinearProgress />}</div>
        {candidateData && (
          <div>
            <div>
              <img
                alt="candidate"
                src={`data:image/jpg;base64,${candidateData.avatar}`}
                height={100}
                width={100}
              />
              <div className="mt-2">
                <p>
                  {candidateData.firstName} {candidateData.lastName}
                </p>
                <p>{candidateData.registrationNumber}</p>
              </div>
            </div>
          </div>
        )}
        {transcript.map((c, i) => (
          <div className="border-bottom">
            <small>Question {i + 1}</small>
            <div className="img-fluid">{parse(c.question.question)}</div>
            <div className="mt-2">
              {c.question.options.map((d, j) => (
                <Stack direction={"row"} spacing={1}>
                  <div>{getOptions(j)}.</div>
                  <div>{parse(d)}</div>
                  <div>
                    {d === c.response &&
                    c.response !== c.question.correctAnswer ? (
                      <Badge bg="danger">Wrong Answer</Badge>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {d === c.question.correctAnswer && c.response === d ? (
                      <Badge bg="success">correct answer</Badge>
                    ) : (
                      ""
                    )}
                  </div>
                </Stack>
              ))}
            </div>
            <div className="col-lg-8 alert alert-info border-0">
              <small>Correct Answer</small>
              {parse(c.question.correctAnswer)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Transcript;

function getOptions(i) {
  switch (i) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";

    default:
      break;
  }
}
