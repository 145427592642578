import React, { useContext, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import logo from "../images/logo.png";
import { Login } from "@mui/icons-material";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";

export default function HomePage() {
  const [loginData, setLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const { setAlertData } = useContext(AlertContext);

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, error } = await httpService.post("auth/login", loginData);
    if (data) {
      localStorage.setItem(
        process.env.REACT_APP_PROJECT_USER,
        JSON.stringify(data)
      );
      window.location.assign("/");
    }
    if (error) {
      setAlertData({ open: true, severity: "error", message: error });
    }

    setLoading(false);
  };
  return (
    <div className="row m-0">
      <div className="col-lg-8 homeImg d-flex justify-content-center align-items-center text-white text-center">
        <div>
          <div className="mb-2">
            <img src={logo} height={150} width={150} alt="logo" />
          </div>

          <Typography
            variant="h2"
            textTransform={"capitalize"}
            fontWeight={700}
            gutterBottom
          >
            Central Server <FontAwesomeIcon icon={faServer} />
          </Typography>
          <hr />
          <div className="mt-2">
            <Typography fontWeight={300}>NMCN</Typography>
            {/* <Typography fontWeight={300}>AGUILA</Typography> */}
          </div>
        </div>
      </div>
      <div className="col-lg-4 d-flex align-items-center">
        <div className="col-lg-8 ms-4">
          <div>
            <div className="mb-4">
              <Typography variant="h3" fontWeight={700} color="GrayText">
                Admin Login
              </Typography>
            </div>
            <form onSubmit={submitForm}>
              <div className="mb-3">
                <TextField
                  onChange={(e) =>
                    setLoginData({ ...loginData, username: e.target.value })
                  }
                  fullWidth
                  label="Username"
                  required
                />
              </div>
              <div className="mb-3">
                <TextField
                  onChange={(e) =>
                    setLoginData({ ...loginData, password: e.target.value })
                  }
                  fullWidth
                  label="Password"
                  type="password"
                  required
                />
              </div>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={loading}
                endIcon={<Login />}
                className="mt-3"
                color="success"
              >
                <span>login</span>
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
