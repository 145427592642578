import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faServer,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Quiz,
  Dashboard,
  Book,
  Sync,
  Search,
  Logout,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { loggedInUser, httpService } from "../httpService";

export default function SideMenu() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const listItems = [
    {
      text: "Dashboard",
      icon: <Dashboard />,
      redirectTo: "/",
    },
    // {
    //   text: "CBT Centres",
    //   icon: <House />,
    //   redirectTo: "/centres/centresmanager",
    // },

    {
      text: "Examination Control",
      icon: <Quiz />,
      redirectTo: `/examination`,
    },
    {
      text: "Sync Operation",
      icon: <Sync />,
      redirectTo: `/candidates/sync`,
    },
    // {
    //   text: "Active Exam Session",
    //   icon: <FontAwesomeIcon icon={faClock} />,
    //   redirectTo: "/examination/activesession",
    // },
    // {
    //   text: "Session Report",
    //   icon: <Chat />,
    //   redirectTo: `/examination/sessionreport`,
    // },
    {
      text: "Programmes",
      icon: <Book />,
      redirectTo: `/programmes`,
    },
    {
      text: "Search Candidates",
      icon: <Search />,
      redirectTo: `/candidates/search`,
    },
    {
      text: "Servers Manager",
      icon: <FontAwesomeIcon icon={faServer} />,
      redirectTo: `/serversmanager`,
    },
    {
      text: "Users",
      icon: <FontAwesomeIcon icon={faUsers} />,
      redirectTo: "/users",
    },
    {
      text: "Daily Attendance",
      icon: <FontAwesomeIcon icon={faCalendar} />,
      redirectTo: "/attendance",
    },
    // {
    //   text: "Admin Panel",
    //   icon: <AdminPanelSettings />,
    //   redirectTo: `/adminPanel`,
    // },
  ];
  const logout = async () => {
    setLoading(true);
    const { data } = await httpService.get("auth/logout");

    if (data) {
      localStorage.removeItem(process.env.REACT_APP_PROJECT_USER);
      window.location.assign("/");
    }
    setLoading(false);
  };
  return (
    <div className="mt-5 mb-5">
      <div
        className="mt-5 mb-5 text-center p-3"
        style={{ backgroundColor: "#7da38c", color: "#fff" }}
      >
        <Typography letterSpacing={2} variant="h4" fontWeight={700}>
          NMCN CENTRAL
        </Typography>
      </div>
      <List>
        {listItems.map((item, index) => (
          <ListItem
            component="div"
            disablePadding
            key={index}
            sx={{ color: "#282828" }}
            className="mb-2"
          >
            <ListItemButton
              onClick={() => navigate(item.redirectTo)}
              sx={{ ":hover": { color: "GrayText" } }}
            >
              <ListItemIcon sx={{ color: "#282828" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        {loggedInUser && (
          <ListItem component="div" disablePadding>
            <ListItemButton
              onClick={logout}
              sx={{ ":hover": { color: "GrayText" } }}
            >
              <ListItemIcon sx={{ color: "#282828" }}>
                {loading ? <CircularProgress size={20} /> : <Logout />}
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </div>
  );
}
