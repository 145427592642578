import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpService } from "../../httpService";
import { CircularProgress, Typography } from "@mui/material";
import { Table } from "react-bootstrap";

export default function PaperTypeCandidates() {
  const { id } = useParams();

  const [paperTypeData, setPaperTypeData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService(`papertype/candidate/${id}`);

    if (data) {
      setPaperTypeData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {loading && <CircularProgress />}
      {paperTypeData && (
        <div>
          <div className="mb-4">
            <Typography
              variant="h4"
              fontWeight={600}
              textTransform={"uppercase"}
            >
              {paperTypeData.paperType.examination.title}
            </Typography>
            <Typography textTransform={"uppercase"}>
              {paperTypeData.paperType.title}
            </Typography>
          </div>
          <div className="col-lg-8">
            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Candidate</th>
                  <th>Registration Number</th>
                  <th>Programme</th>
                  {/* <th>Score</th> */}
                </tr>
              </thead>
              <tbody>
                {paperTypeData.candidates.length > 0 ? (
                  <>
                    {paperTypeData.candidates.map((c, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          <Typography>
                            {c.firstName} {c.lastName}
                          </Typography>
                        </td>
                        <td>
                          <Typography textTransform={"uppercase"}>
                            {c.registrationNumber}
                          </Typography>
                        </td>
                        <td>
                          {c.programme && (
                            <Typography textTransform={"uppercase"}>
                              {c.programme.name}
                            </Typography>
                          )}
                        </td>
                        {/* <td>{c.score}</td> */}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td>No candidates found for this paper type</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
